import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import React, { useMemo } from 'react';
import _ from 'lodash';
// Import ag grid
import { AgGridReact } from 'ag-grid-react';
// Connect enterprise
import { LicenseManager } from 'ag-grid-enterprise';
import { getCementoStringValue } from '../../utils';
// Activate the license
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

const ObjectsWrapperListViewAGGrid = ({ data }) => {
  const { rowData, colDefs } = useMemo(
    () =>
      Object.entries(data).reduce(
        (acc, [, value]) => {
          acc.rowData = [
            ...acc.rowData,
            ...value.data.map((item) => {
              const res = {};
              _.each(item, (value, key) => {
                let processedValue = getCementoStringValue(value);
                if (typeof processedValue === 'string') {
                  res[key] = processedValue;
                  acc.colDefs.add(key);
                }
              });
              return res;
            }),
          ];
          return acc;
        },
        {
          rowData: [],
          colDefs: new Set(),
        }
      ),
    [data]
  );
  const processedColDefs = Array.from(colDefs).map((key) => ({ field: key, enableRowGroup: true }));

  if (colDefs.length === 0) return;
  return (
    <div
      className='ag-theme-quartz' // applying the Data Grid theme
      style={{ height: '100%' }} // the Data Grid will fill the size of the parent container
    >
      <AgGridReact
        rowGroupPanelShow={'always'}
        groupDefaultExpanded={0}
        rowData={rowData}
        columnDefs={processedColDefs}
      />
    </div>
  );
};

export default ObjectsWrapperListViewAGGrid;
