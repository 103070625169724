import GenericCardWrapper from './GenericCardWrapper';
import { CardBody, CardHeader } from '../../../../../web/components';
import PostHeader from '../../../../../web/views/Posts/PostHeader';
import Text from '../../../../../web/components/CementoComponents/Text';
import User from '../../../../../web/components/CementoComponents/User';
import Image from '../../../../../web/components/CementoComponents/Image';
import theme from '../../../../../web/assets/css/theme';
import issuesMessages from '../../../../issues/issuesMessages';
import PdfIcon from '../../../../../web/assets/img/icons/pdf.png';
import { ObjectsWrapperManipulationContext } from '../../../ObjectsWrapper';
import { useContext } from 'react';

const processAttachments = (objects) => {
  let result = [];
  if (objects) result = Object.values(objects || {});
  if (result.length == 0) return null;

  return result.filter((curr) => !curr?.isDeleted).map((curr) => curr?.uri);
};

const PostCard = (props) => {
  const { selectedObject, setSelectedObject } = useContext(ObjectsWrapperManipulationContext);

  const {
    id: objectId,
    title,
    assignTo,
    trade,
    images: imagesObject,
    attachments: attachmentsObject,
    location,
    issueState,
    style,
    rtl,
  } = props.data;
  const isSelected = selectedObject?.id === objectId;
  const userId = assignTo?.id;

  let images = processAttachments(imagesObject);
  let attachments = processAttachments(attachmentsObject);

  let postHeaderHeight = 40;
  let height = 175;
  let defaultContainerStyle = {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 20px 2px rgba(0, 0, 0, 0.15)',
      backgroundColor: theme.backgroundColorSelected,
    },
    height,
    overflow: 'hidden',
    backgroundColor: isSelected ? theme.backgroundColorSelected : theme.backgroundColorBright,
  };

  let blocksContainerStyle = {
    border: isSelected ? '2px solid ' + theme.brandPrimary : theme.borderLineNeutralLight + '30',
    borderRadius: rtl ? '6px 0px 6px 6px' : '0 6px 6px',
    boxShadow: isSelected ? '0px 0px 20px 2px rgba(0, 0, 0, 0.15)' : 'none',
  };

  const onClick = () => {
    return setSelectedObject({ objectId });
  };

  return (
    <GenericCardWrapper
      key={title}
      onClick={onClick}
      style={style || Object.assign({}, defaultContainerStyle, blocksContainerStyle)}>
      <CardHeader
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: postHeaderHeight,
          padding: theme.padding,
          borderBottom: theme.borderLineNeutralLight + '30',
        }}>
        <PostHeader
          post={{
            trade,
            location,
            issueState: issueState?.id,
          }}
        />
      </CardHeader>
      <CardBody
        style={{
          display: 'flex',
          height: height - postHeaderHeight,
          padding: 0,
        }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            padding: theme.paddingSize,
            paddingBottom: 5,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flex: 1,
            }}>
            <div style={{ [rtl ? 'marginLeft' : 'marginRight']: 10 }}>
              <p
                style={{
                  ...theme.defaultFont,
                  fontWeight: theme.bold,
                  color: theme.brandNeutralDark,
                  maxHeight: 40,
                  overflow: 'hidden',
                  fontSize: '0.8vw',
                  textOverflow: 'ellipsis',
                  margin: '0px',
                  whiteSpace: 'wrap',
                }}>
                {title.cementoValue.getCementoTitle()}
              </p>
            </div>
            {userId ? (
              <div>
                <Text
                  style={{
                    ...theme.subFont,
                    marginBottom: 3,
                    marginTop: 3,
                    overflow: 'hidden',
                    fontSize: '0.8vw',
                    textOverflow: 'ellipsis',
                    margin: '0px',
                    whiteSpace: 'wrap',
                  }}>
                  {issuesMessages.assignToTitle}
                </Text>
                <User avatarSize={25} companyMode={true} detailedOverflow={!images} userId={userId} />
              </div>
            ) : null}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            width: height - postHeaderHeight,
            [rtl ? 'borderRight' : 'borderLeft']: images && !attachments ? null : theme.borderLineNeutralLight + '30',
          }}>
          {attachments && attachments.length ? (
            <img src={PdfIcon} style={{ width: '60%', alignSelf: 'center' }} />
          ) : (
            <Image src={images ? images[0] : null} />
          )}
        </div>
      </CardBody>
    </GenericCardWrapper>
  );
};

export default PostCard;
