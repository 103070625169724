import { useContext, useEffect, useMemo } from 'react';
import { ProjectContext } from '../../projects/contexts';
import { useCementoRouter } from '../../../web/components/Router/util/withRouterHOC';
import ObjectsWrapperView from '../ObjectsWrapperView';
import PostCard from '../views/grid/components/PostCard';
import { preProcessInstances } from '../utils';
import { useSelector } from 'react-redux';
import { lokiInstance } from '../../configureMiddleware';
import useSavedMenus from '../../../web/components/Header/useSavedMenus';
import _ from 'lodash';
import { fillBusinessType } from '../../propertiesTypes/funcs';
import useCompanies from '../../companies/hooks/useCompanies';
import DataManagerInstance from '../../dataManager/DataManager';
import { SUBJECTS } from '../../dataManager/subjects';
import useIntl from '../../intl/useIntl';
import { getAllLocationTitlesMap } from '../../../web/views/Locations/funcs';

const usePreProcessedInstances = ({ objects, instances, propTypes, locationsTitlesMap }) => {
  return useMemo(() => {
    const preProcessedInstances = preProcessInstances(objects, instances, propTypes, locationsTitlesMap);
    return preProcessedInstances;
  }, [objects, instances, propTypes]);
};

const connectToSubjects = (selectedProjectId, viewer, subjectType) => {
  const basicParams = {
    scope: 'projects',
    scopeId: selectedProjectId,
    viewer,
  };

  DataManagerInstance.loadAndConnect({
    ...basicParams,
    subject: SUBJECTS.PROPERTIES_INSTANCES,
    queryParams: { subjectName: `${subjectType}Info` },
  });

  let extraParams = { subject: subjectType };

  if (subjectType !== 'locations') DataManagerInstance.loadAndConnect({ ...basicParams, ...extraParams });
};

const InstancesPreProcessorHOC = (WrappedComponent) => {
  const WithInstances = ({ contentType, section }) => {
    const intl = useIntl();
    const { selectedFav } = useSavedMenus({
      contentType,
      section,
    });
    const projectProps = useContext(ProjectContext);

    const { selectedProjectId, viewer, buildings, floors, units, propertiesSections } = projectProps;
    const subjectType = selectedFav?.subjectType;
    const subjectName = `${subjectType}Info`;

    useEffect(() => {
      connectToSubjects(selectedProjectId, viewer, subjectType);
    }, [selectedProjectId, subjectType]);

    const outletProps = useCementoRouter();

    const { companies } = useCompanies();
    const { trades, members, subCategories, propertiesTypes } = useSelector((state) => ({
      trades: state.trades.map,
      subCategories: state.quasiStatics.subCategoriesMap,
      members: state.members.map,
      propertiesTypes: state.propertiesTypes.projectProperties?.[projectProps.selectedProjectId],
    }));

    const richPropTypes = useMemo(() => {
      const rawPropTypes = propertiesTypes?.[subjectName];
      const rawPropertiesSections = propertiesSections?.[subjectName];
      return rawPropTypes
        ? Object.entries(rawPropTypes).reduce((acc, [key, prop]) => {
            let newProp = prop;
            if (prop.businessType) {
              newProp = fillBusinessType(prop, {
                trades,
                companies,
                members,
                subCategories,
              });
            }
            if (rawPropertiesSections && prop.sectionId) {
              newProp.section = rawPropertiesSections[prop.sectionId];
            }
            acc[key] = newProp;
            return acc;
          }, rawPropTypes)
        : rawPropTypes;
    }, [propertiesSections, trades, members, subCategories, propertiesTypes]);

    const propertiesInstances = useMemo(() => {
      const lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
      return lokiPropertyInstances.cementoFind({
        'projectId': projectProps.selectedProjectId,
        subjectName,
      });
    }, [projectProps.selectedProjectId, subjectName]);

    const locationsTitlesMap = useMemo(() => {
      return getAllLocationTitlesMap(buildings, floors, units, intl);
    }, [projectProps.selectedProjectId, subjectName]);

    const objects = usePreProcessedInstances({
      objects: outletProps.currViewPosts, // TODO: replace with dynamic field
      instances: propertiesInstances,
      propTypes: richPropTypes,
      locationsTitlesMap,
    });

    const shouldRender = !!(objects && richPropTypes);
    // TODO INCLUDE INTO RICH PROP TYPES
    const columnVisibility = selectedFav?.values?.columnVisibility;

    if (!shouldRender) return null;
    return (
      <WrappedComponent
        // TODO
        subjectType={subjectType}
        contentType={contentType}
        propertiesInstances={propertiesInstances}
        //
        objects={objects}
        propTypes={richPropTypes}
        columnVisibility={columnVisibility}>
        <ObjectsWrapperView
          contentType={contentType}
          selectedProjectId={projectProps.selectedProjectId}
          ItemComponent={PostCard}
        />
      </WrappedComponent>
    );
  };
  return WithInstances;
};

export default InstancesPreProcessorHOC;
