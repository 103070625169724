import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import './index.css';
import { ObjectsWrapperDataContext, ObjectsWrapperManipulationContext } from './ObjectsWrapper';
import FilterBar from './components/FilterBar';
import ObjectsWrapperGridView from './views/grid/ObjectsWrapperGridView';
import ObjectsWrapperListViewReactTables from './views/table/ObjectsWrapperListViewReactTables';
import ObjectsWrapperListViewAGGrid from './views/table/ObjectsWrapperListViewAGGrid';
import ObjectsWrapperListViewTanStackTable from './views/table/TanStackTable/ObjectsWrapperListViewTanStackTable';
import SidebarBuilding from '../../web/layouts/SidebarBuilding';
import useIntl from '../intl/useIntl';
import { useSelector } from 'react-redux';
import { FILTER_URL_KEY } from '../../web/app/constants';
import { encodeFilterToSearch } from '../../web/app/funcs';
import SplitViewPage from '../../web/layouts/SplitViewPage';

const ViewsMap = {
  tiles: ObjectsWrapperGridView,
  table: ObjectsWrapperListViewTanStackTable,
  reactTables: ObjectsWrapperListViewReactTables,
  agGrid: ObjectsWrapperListViewAGGrid,
};
/**
 *
 * @param {{ viewType?: 'grid' | 'list' }} props
 * @returns
 */
const ObjectsWrapperView = (props) => {
  const { ItemComponent } = props;
  const { data } = useContext(ObjectsWrapperDataContext);
  const { selectedObject, viewType, filters, setFilters } = useContext(ObjectsWrapperManipulationContext);
  // START TEMP to showcase the reactTables view
  const [searchParams] = useSearchParams();
  // END TEMP
  const ViewWrapper = useMemo(() => {
    return ViewsMap[searchParams.get('tableVariant')] || ViewsMap[viewType];
  }, [viewType]);

  const intl = useIntl();
  const rtl = useSelector((state) => state.app.rtl);

  const onLocationChange = (selectedLocation) => {
    setFilters((filters) => {
      const locationId = [selectedLocation[`${selectedLocation.type}Id`]];
      const newFilter = {
        ...(filters?.newFilter || {}),
        locationId,
      };
      return {
        ...(filters || {}),
        newFilter,
        newSearch: encodeFilterToSearch(newFilter, location.search, FILTER_URL_KEY),
      };
    });
  };
  // WIP WORK OF SIDE CARD BELOW
  const infoCardMode = 'side';
  const sideCardObject = selectedObject;
  const closeSelectedCell = () => {
    // const { onSideClose } = this.props;
    // this.setState({ selectedCell: null, sideCardObject: null });
    // if (onSideClose) onSideClose();
  };
  return (
    <SplitViewPage
      rtl={rtl}
      onSideClose={closeSelectedCell}
      withHorizontalScroll={true}
      SideStack={Boolean(sideCardObject) && [sideCardObject]}
      sideStackObjectId={sideCardObject?.props?.objectId}
      mode={infoCardMode}
      Main={
        <main key='ObjectsWrapperView' className='ObjectsWrapperView_root'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <FilterBar viewType={viewType} />
            <div
              className='ObjectsWrapperView_content'
              style={{
                display: 'flex',
              }}>
              <SidebarBuilding
                navigationParams={filters?.location || {}}
                selectedLocation={filters?.location || {}}
                rtl={rtl}
                intl={intl}
                mainComponentStyle={{ height: '100%' }}
                location={location}
                selectedProjectId={props.selectedProjectId}
                contentType={props.contentType}
                showBadges={true}
                filteredPosts={data}
                onChange={onLocationChange}
              />
              {Object.keys(data).length ? <ViewWrapper ItemComponent={ItemComponent} /> : null}
            </div>
          </div>
        </main>
      }
    />
  );
};

export default ObjectsWrapperView;
