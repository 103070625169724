import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext, FiltersSortsContext } from '../../../common/projects/contexts';
import { Outlet } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { track, setReportingScope } from '../../../common/lib/reporting/actions';
import { saveUIParams } from '../../../common/ui/actions';
import { setLastVisitedProject } from '../../../common/users/actions';
import {
	enterProject,
	leaveProject,
	saveProjectLokiStorage,
} from '../../../common/projects/actions';
import { startLoading, hideLoading, setLang } from '../../../common/app/actions';

import NoItemsFound from '../../components/CementoComponents/NoItemsFound';
import theme from '../../assets/css/theme';
import projectsMessages from '../../../common/projects/projectsMessages';
import { HeaderLinks } from '../../components';
import { PostsHOC } from '../../../common/posts/hooks/usePosts';
import { checkAndFetchSpecificProps } from '../../../common/propertiesTypes/funcs';
import { SUBJECT_NAMES } from '../../../common/propertiesTypes/propertiesTypes';
import moment from 'moment-timezone';
import withRouterHOC from '../../components/Router/util/withRouterHOC.js';
import { CompaniesHOC } from '../../../common/companies/hooks/useCompanies/index.js';
import DataManagerInstance from '../../../common/dataManager/DataManager.js';
import { SUBJECTS } from '../../../common/dataManager/subjects.js';

class ProjectContainerPage extends React.Component {
  constructor(props) {
    super(props);
    this.checkProjectDidLoad = this.checkProjectDidLoad.bind(this);
    this.handleUnmountBeforeBrowserExit = this.handleUnmountBeforeBrowserExit.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.setHeaderParams = this.setHeaderParams.bind(this);
    this.handleEnterProject = this.handleEnterProject.bind(this);
    this.handleLeaveProject = this.handleLeaveProject.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);
    this.updateLanguageIfDiff = this.updateLanguageIfDiff.bind(this);
    this.loadingInterval = {};
    this.state = {
      filteredPosts: [],
      filterVal: "",
    };
  }

  handleUnmount(beforeBrowserExit) {
    const { selectedProjectId } = this.props;
    if (selectedProjectId) {
      if (!beforeBrowserExit || (beforeBrowserExit && DataManagerInstance.isProjectStorageLoaded(selectedProjectId)))
        this.handleLeaveProject(selectedProjectId);
    }
    this.props.hideLoading();
    window.removeEventListener("beforeunload", this.handleUnmountBeforeBrowserExit);
  }

  handleUnmountBeforeBrowserExit() {
    this.handleUnmount(true);
  }

  componentWillUnmount() {
    this.handleUnmount();
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("beforeunload", this.handleUnmountBeforeBrowserExit);
    let selectedProjectId = this.props.match.params.selectedProjectId;

    if (selectedProjectId)
      this.handleEnterProject({}, this.props);
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = this.props;

    this.handleEnterProject(this.props, nextProps);
    if (match.params.selectedProjectId && match.params.selectedProjectId != nextProps.match.params.selectedProjectId)
      this.handleLeaveProject(match.params.selectedProjectId);

    this.setComponentData(this.props, nextProps);
  }

  async handleEnterProject(props, nextProps) {
    const { enterProject, setLastVisitedProject, projects, track, setReportingScope, } = nextProps;
    let URL_projectId = props.getNested(["match", "params", "selectedProjectId"]);
    let nextURL_projectId = nextProps.getNested(["match", "params", "selectedProjectId"]);

    if (nextURL_projectId && !this.waitForIntlProviderRerender) {
      if (URL_projectId != nextURL_projectId) {
        track("enterProject", { eventProjectId: nextURL_projectId });

        // IMPORTANT! - this happens before enterProject() in case the lenguage of the page is going to rerander all page...
        setLastVisitedProject(
          projects.getNested([nextURL_projectId], {}),
          projects.getNested([nextURL_projectId], {}),
          projects.getNested([URL_projectId], {}),
          true,
          false,
        );

        const timeZone = projects.getNested([nextURL_projectId, 'tzLocation'], 'Asia/Jerusalem');
        moment.tz.setDefault(timeZone);

        this.updateLanguageIfDiff(nextProps, nextURL_projectId);
        if (this.waitForIntlProviderRerender) return; // IMPORTANT! - setLang() is going to rerender the intlProvider and everything else, so in that case we "waitForIntlProviderReelement=true" and break the sequence

        enterProject(nextURL_projectId);
        setReportingScope({
          viewer: nextProps.viewer,
          projectId: nextURL_projectId,
        });
      } else if (nextURL_projectId == nextProps.selectedProjectId) {
        let projectId = props.selectedProjectId;
        let nextProjectId = nextProps.selectedProjectId;
        if (!nextProjectId || nextProjectId == "null") return null;

        this.updateLanguageIfDiff(nextProps, nextProjectId);
        if (this.waitForIntlProviderRerender) return; // IMPORTANT! - setLang() is going to rerender the intlProvider and everything else, so in that case we "waitForIntlProviderReelement=true" and break the sequence

        const isProjectStorageLoaded = DataManagerInstance.isProjectStorageLoaded(nextProjectId)

        if (isProjectStorageLoaded)
          this.checkProjectDidLoad(props, nextProps);

        if (projectId !== nextProjectId && !isProjectStorageLoaded) {
          this.checkProjectDidLoad(props, nextProps);
        }
        // Only after => projectStorage is loaded || we change projects and projectStorage already loaded
        else if (
          isProjectStorageLoaded && 
          DataManagerInstance.projectLokiLoaded[nextProjectId] && (
            props.isValDiff(nextProps, ['storageCleaned']) || 
            projectId !== nextProjectId
          )
        ) {
            this.getProjectData(nextProps);
        }
      }
    }
  }

  updateLanguageIfDiff(nextProps, projectId) {
    const { setLang } = nextProps;
    if (!this.waitForIntlProviderRerender) {
      let newLang = nextProps.getNested(["projectsMap", projectId, "lang"], "en");
      let currLang = nextProps.lang;
      if (currLang != newLang) {
        this.waitForIntlProviderRerender = true;
        setLang(newLang);
      }
    }
  }
  


  handleLeaveProject(projectId) {
    const { loading, hideLoading, leaveProject } = this.props;
    leaveProject(projectId);
    if (loading) hideLoading();
    if (this.saveProjectStorageInterval) clearInterval(this.saveProjectStorageInterval);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (props.isValDiff(nextProps, ["navigationParams", "scope"]))
      if (nextProps.getNested(["navigationParams", "scope"]) == "company") {
        let selectedCompanyId = nextProps.getNested(["navigationParams", "selectedCompanyId"], "_");
        nextProps.history.push(`/main/companyContainerPage/${selectedCompanyId}/_`);
      }

    if (props.isValDiff(nextProps, ["rtl"]))
      document.body.style.direction = nextProps.rtl ? "rtl" : "ltr";

    if (props.firstMount || props.selectedProjectId !== nextProps.selectedProjectId || (nextProps.cleanCacheRevokes && props.cleanCacheRevokes != nextProps.cleanCacheRevokes)) {
      this.getProjectData(nextProps);
    }

    if (props.isValDiff(nextProps, ["filtersView"]) ||
      props.isValDiff(nextProps, ["filters"]) ||
      props.isValDiff(nextProps, ["buildings"]) ||
      props.isValDiff(nextProps, ["viewer"]) ||
      props.isValDiff(nextProps, ["navigationParams", "contentType"]) ||
      props.isValDiff(nextProps, ["navigationParams", "page"]) ||
      props.isValDiff(nextProps, ["location", "search"]) ||
      props.isValDiff(nextProps, ["detailedProjects", nextProps.selectedProjectId]) ||
      props.isValDiff(nextProps, ["selectedProjectId"])){
      newStateChanges = { ...newStateChanges, filterVal: '' }
    }

    this.checkAndHandleSpecificPropsUpdates(props, nextProps)
   
    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }


  checkAndHandleSpecificPropsUpdates(prevProps, nextProps) {
    const { selectedProjectId: projectId, viewer } = nextProps;

    checkAndFetchSpecificProps({
      viewer,
      projectId,
      prevSpecificPropertiesUpdatesAvailable: prevProps.getNested(['specificPropertiesUpdatesAvailable']),
      nextSpecificPropertiesUpdatesAvailable: nextProps.getNested(['specificPropertiesUpdatesAvailable'])
    });
  }

  clearFilterVal = () => {
    this.setState({ filterVal: "" });
  };

  setFilterVal = filterVal => {
    this.setState({ filterVal });
  };

  checkProjectDidLoad(props, nextProps) {
    const { hideLoading, saveProjectStorage, selectedProjectId } = nextProps;

    const scopeParams = { scope: 'projects', scopeId: selectedProjectId };

    const subjectsToLoad = [
      { ...scopeParams, subject: SUBJECTS.BUILDINGS },
      { ...scopeParams, subject: SUBJECTS.FLOORS },
      { ...scopeParams, subject: SUBJECTS.UNITS },
      { scope: 'global', subject: SUBJECTS.TRADES }
    ]
    
    const allNextPropsLoaded = subjectsToLoad.every(params => DataManagerInstance.isDataReady(params))

    // Means that the last relevant project storage was loaded
    // if we are in dashboard page URL
    const isDashboardLocation = nextProps.getNested(['location', 'pathname'], '').indexOf('dashboard') != -1;
    if (isDashboardLocation && (nextProps.loading || this.props.loading)) {
      hideLoading();
    }

    // Means that the last relevant project storage was loaded
    if (allNextPropsLoaded || props.selectedProjectId != nextProps.selectedProjectId) {
      if (!this.loadingInterval[nextProps.selectedProjectId])
        this.loadingInterval[nextProps.selectedProjectId] = setInterval((() => {
          if (nextProps.loading || this.props.loading) {
            hideLoading();
            clearInterval(this.loadingInterval[nextProps.selectedProjectId]);
            this.loadingInterval[nextProps.selectedProjectId] = null;
            delete this.loadingInterval[nextProps.selectedProjectId];
          }
        }).bind(this), 100);

      clearInterval(this.saveProjectStorageInterval);
      this.saveProjectStorageInterval = setInterval(() => {
        saveProjectLokiStorage(selectedProjectId);
      }, 40000);
    } 
    if (!selectedProjectId)
      hideLoading();
  }

  async getProjectData(nextProps) {
    const nextProjectId = nextProps.selectedProjectId;

    if (!nextProjectId || nextProjectId == "null" || !nextProps.viewer) {
      return null;
    }

    /*
      || ============================ ||
      ||       LOADING PIPELINE       ||
      || ============================ ||
    */

    const connectionParams = {
      scope: 'projects',
      scopeId: nextProjectId,
      viewer: nextProps.viewer,
    }
    
    const subjectNames = Object.values(SUBJECT_NAMES);

    DataManagerInstance.loadAndConnect({ subject: SUBJECTS.CONFIGURATIONS, ...connectionParams })
      .then(async () => {
        await Promise.all([
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.STAGES, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.CHECKLISTS, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.CHECKLIST_ITEMS, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.BUILDINGS, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.FLOORS, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.UNITS, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.PROJECT, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.USERS, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.COMPANIES, ...connectionParams }),
        subjectNames.forEach(subjectName => {
          DataManagerInstance.loadAndConnect({ subject: SUBJECTS.PROPERTIES_TYPES, ...connectionParams, queryParams: { subjectName }})
          DataManagerInstance.loadAndConnect({ subject: SUBJECTS.PROPERTIES_MAPPINGS, ...connectionParams, queryParams: { subjectName }})
        })
       ])
      })
  }

  setHeaderParams(headerParams) {
    const { match, uiParams, menus, saveUIParams } = this.props;
    let contentType = match.params.contentType;
    let isOnlySideNav = Boolean((!headerParams || !headerParams.headerComponent) && menus?.[contentType]);
    if (uiParams.getNested(["onlySideNav"]) != isOnlySideNav)
      saveUIParams({ onlySideNav: isOnlySideNav });
    this.setState({ headerParams });
  }

  getPostsFilters = () => {
    const VALID_POSTS_CONTENT_TYPES = {records:true, tasks:true, issues:true};
    const urlContentType = this.props.getNested(['navigationParams', "contentType"]);
    return {
      contentType: urlContentType == "safety" ? 'safety' : null,
      postsType: VALID_POSTS_CONTENT_TYPES[urlContentType] ? urlContentType : this.props.getNested(['navigationParams', "queryParams", "itemType"], null),
      filterValue: this.state.filterVal,
    }
  }

  render() {
    const { menus, projectPermitted, intl, location, selectedProjectId } = this.props;
    const { headerParams, filterVal } = this.state;
    
    if (!selectedProjectId)
      return null;

    // 
    // TODO:NAVIGATION Looks like it works without a need of the previously existing redirect
    // But I've added this logic just in case so it can be uncommented at any time if related issues appear
    // 
    // let URL = match.url.endsWith("/") ? match.url : match.url + "/";
    // let query = this.props.getNested(["location", "search"], "");
    // if (this.props.match.isCurrentPage('_')) {
    //   this.props.history.push(URL.replace('/_', '/issues/dashboard') + query);
    // }
    // 

    if (!projectPermitted)
      return (<NoItemsFound key={"projectPermitted"} style={{ fontSize: 22, color: theme.brandPrimary, margin: 5 }} message={projectsMessages.notPermittedToWebProject} />);

    return (
      <PostsHOC filters={this.getPostsFilters()}>
        {({ filteredPosts, currViewPosts }) => (
          <div
            style={{
              display: 'flex',
              flexDirection: headerParams && headerParams.headerComponent ? 'column' : 'row',
              flex: 1,
            }}>
            <HeaderLinks
              key='secondaryHeader'
              defaultSelection={null}
              headerParams={headerParams}
              routingMode={true}
              menus={menus}
              menuMode={true}
              intl={intl}
              location={location}
              isSecondary={true}
            />
            <Outlet
              context={{
                currViewPosts: currViewPosts,
                filteredPosts: filteredPosts,
                filterVal: filterVal,
                clearFilterVal: this.clearFilterVal,
                setFilterVal: this.setFilterVal,
                setHeaderParams: this.setHeaderParams,
              }}
            />
          </div>
        )}
      </PostsHOC>
    );
  }
}

const enhance = compose(
  injectIntl,
  withRouterHOC,
	connectContext(ProjectContext.Consumer),
	connectContext(FiltersSortsContext.Consumer),
  CompaniesHOC,
	connect(
		state => ({
			projectsLastUpdateAvailable: state.projects.lastUpdateAvailable,
			configurationsLastUpdateAvailable: state.configurations.lastUpdateAvailable,
			buildingsLastUpdateAvailable: state.buildings.lastUpdateAvailable,
			floorsLastUpdateAvailable: state.floors.lastUpdateAvailable,
			unitsLastUpdateAvailable: state.units.lastUpdateAvailable,
			drawingsLastUpdateAvailable: state.drawings.lastUpdateAvailable,
			stagesLastUpdateAvailable: state.stages.lastUpdateAvailable,
			checklistsLastUpdateAvailable: state.checklists.lastUpdateAvailable,
			checklistItemsLastUpdateAvailable: state.checklistItems.lastUpdateAvailable,
			propertiesTypesLastUpdateAvailable: state.propertiesTypes.lastUpdateAvailable,
			propertiesMappingsLastUpdateAvailable: state.propertiesMappings.lastUpdateAvailable,

			allMembers: state.members.map,

      projectsLoadingState: state.dataManager.projects,
      globalLoadingState: state.dataManager.global,

			specificPropertiesUpdatesAvailable: state.propertiesTypes.specificPropertiesUpdatesAvailable,
      membersLastClientUpdatePerProject: state.members.lastClientUpdatePerProject,

			cleanCacheRevokes: state.ui.cleanCacheRevokes,

			projectsMap: state.projects.map,
			lang: state.app.lang,
			rtl: state.app.rtl,
			uiParams: state.ui.uiParams,
			storageCleaned: state.app.storageCleaned,
      loadingReducer: state.dataManager
		}),
		{
			setLastVisitedProject,
			saveProjectLokiStorage,
			enterProject,
			leaveProject,
			startLoading,
			hideLoading,
			setLang,
			track,
			saveUIParams,
			setReportingScope,
		},
	),
);
export default enhance(ProjectContainerPage);