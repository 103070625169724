import React, { Component } from 'react';
import { connect  } from 'react-redux'; 
import { getViewer } from '../../../common/users/funcs';
import { getPropInstancesPerProject } from '../../../common/propertiesInstances/funcs';
import { getCompanyPropertiesMappings } from '../../../common/propertiesMappings/funcs';
import { getCompanyPropertiesTypesAndSections, fillBusinessType } from '../../../common/propertiesTypes/funcs';
import { ProjectContext } from '../../../common/projects/contexts';
import * as permissionsFunc from '../../../common/permissions/funcs'
import { cleanPermissionBasedData, startStateLogRequestsListener } from '../../../common/app/actions';
import _ from 'lodash';
import { DEFAULT_VIEWS_CONFIGURATIONS } from '../../../common/app/constants'
import { CementoRecordObject } from '../../../common/transit';
import { startLastUpdatesStatsListener } from '../../../common/lastUpdatesV2/funcs';
import ClientServerConnectivityManagerInstance from '../../../common/lib/ClientServerConnectivityManager';
import { getDefaultCompanyMenus, getProjectCombinedMenus, getDefaultProjectMenus, getMenus } from '../../menus/funcs';
import withRouterHOC from '../../components/Router/util/withRouterHOC';
import { CompaniesHOC } from '../../../common/companies/hooks/useCompanies';
import DataManagerInstance from '../../../common/dataManager/DataManager';
import { SUBJECTS } from '../../../common/dataManager/subjects';

window.permissionsFunc = permissionsFunc;

class ScopeContextPage extends Component {  
  constructor(props, context) {
    super(props, context);
    this.getProjectProperties = this.getProjectProperties.bind(this);
    this.getProjectCompanies = this.getProjectCompanies.bind(this);
    this.getProjectScopeMembers = this.getProjectScopeMembers.bind(this);
    this.buildDefaultProjectMenus = this.buildDefaultProjectMenus.bind(this);
    this.dispatchBasedOnScope = this.dispatchBasedOnScope.bind(this);
    this.getCompanyStateChanges = this.getCompanyStateChanges.bind(this);
    this.getProjectStateChanges = this.getProjectStateChanges.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.criticalCodeValues = {};
    this.didFetchScopeMenus = {};
    this.originalProjectMenus = {};
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { detailedProjects, configurations, companiesConfigurations, trades, titles, projects, buildings, floors, units, rtl, loading, selectedProjectId:selectedProjectIdProp, viewer, navigationParams } = this.props;
    let scope = this.props.getNested(['navigationParams', 'scope']);

    const selectedProjectId =  selectedProjectIdProp || navigationParams.selectedProjectId

    let contextState = { 
      rtl,
      selectedProjectId,
      viewer, 
      loading,
      trades, 
      titles, 
      projects: (scope == 'company' ? this.state.projects || {} : projects),
      //relevantProjectIds: (scope == 'company' ? Object.keys(this.state.companyProjects || noValue) : [selectedProjectId]),
      detailedProjects: (scope == 'company' ? this.state.detailedProjects || noValue : detailedProjects),
      configurations:(scope == 'company' ? companiesConfigurations || {} : configurations).getNested([selectedProjectId], noValue),
      project: (scope == 'company' ? this.state.projects || {} : projects).getNested([selectedProjectId], arrayNoValue),
      lang: (scope == 'company' ? this.state.projects || {} : projects).getNested([selectedProjectId,'lang'],'en'),
      buildings: buildings.getNested([selectedProjectId], noValue), 
      floors: floors.getNested([selectedProjectId], noValue), 
      units: units.getNested([selectedProjectId], noValue),
    };

    this.setState(contextState, () => {
      this.setComponentData({ firstMount: true }, this.props);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  componentWillUnmount() {
    this.endLastUpdatesRequestsListener?.();
    this.removeStateLogRequestsListener?.();
    this.removeStateLogRequestsListener = undefined;
  }

  setComponentData(props, nextProps) {
    const { detailedProjects, configurations, companiesConfigurations, trades, titles, projects, buildings, floors, units, rtl, loading, startStateLogRequestsListener } = nextProps;

    this.setState({ loading });
    
    let newStateChanges = this.dispatchBasedOnScope({ 
      company: () => this.getCompanyStateChanges, 
      project: () => this.getProjectStateChanges, 
      defaultAction: () => () => ({}),
      scope: nextProps.getNested(['navigationParams', 'scope'])
    })(props, nextProps);

    const isDiffViewer = props.isValDiff(nextProps, ['viewer', 'id']);
    this.endLastUpdatesRequestsListener = startLastUpdatesStatsListener(nextProps.viewer);
    if ((!this.removeStateLogRequestsListener && nextProps.viewer) || isDiffViewer) {
      this.removeStateLogRequestsListener = startStateLogRequestsListener(nextProps.viewer).payload.off;
    }

    if (isDiffViewer) {
      ClientServerConnectivityManagerInstance.viewer = nextProps.viewer?.safeToJS?.();
    }

    if (newStateChanges.scope != nextProps.getNested(['navigationParams', 'scope']))
      newStateChanges.scope = nextProps.getNested(['navigationParams', 'scope']);

    const scope = newStateChanges.scope || this.state.scope;
    const selectedProjectId = newStateChanges.selectedProjectId || this.state.selectedProjectId;

    const isCompanyMode = Boolean(scope == 'company');
    const isAdmin = Boolean(nextProps.getNested(['viewer', 'adminMode']) > 0);

    let nextProjects = (isCompanyMode ? newStateChanges.projects || this.state.projects : projects) || {};
        nextProjects = nextProjects.toJS ? nextProjects : Object.values(nextProjects); // On purpose not converting to JS. Using built in methods accordingly below
    
    /// getting relevant companies configurations
    if (isCompanyMode) {
      let prevProjects = (isCompanyMode ? this.state.projects : props.projects) || {};
      prevProjects = prevProjects.toJS ? prevProjects : Object.values(prevProjects); // On purpose not converting to JS. Using built in methods accordingly below

      let prevCompanies = [];
      (prevProjects || []).forEach((p) => prevCompanies.push(p.companyId));
      let nextCompanies = [];
      (nextProjects || []).forEach((p) => nextCompanies.push(p.companyId));
      let newRelevantCompanies = _.difference(nextCompanies, prevCompanies).filter(_.identity);
      if (newRelevantCompanies.length && !isAdmin)
        _.uniq(newRelevantCompanies).forEach((companyId) => {
          DataManagerInstance.loadAndConnect({
            subject: 'configurations',
            scope: 'companies',
            scopeId: companyId,
            viewer: nextProps.viewer,
          });
        });
    }
    
    const nextConfigurations = (isCompanyMode ? companiesConfigurations || {} : configurations).getNested([selectedProjectId], noValue)
      // TODO: Make the state and the context the same - no checklist vs permittedChecklist but only 1
      let nextState = { 
        rtl,
        trades, 
        titles, 
        projects: nextProjects,
        detailedProjects: (isCompanyMode ? this.state.detailedProjects || noValue : detailedProjects),
        configurations: nextConfigurations,
        viewsConfigurations : nextConfigurations.view || DEFAULT_VIEWS_CONFIGURATIONS,
        project: (detailedProjects || {}).getNested([selectedProjectId], arrayNoValue),
        lang: nextProjects.getNested([selectedProjectId, 'lang'], 'en'),
        buildings: buildings.getNested([selectedProjectId], noValue), 
        floors: floors.getNested([selectedProjectId], noValue), 
        units: units.getNested([selectedProjectId], noValue),
        ...newStateChanges,
        loading,
      };

      if (newStateChanges.companyProjects && (newStateChanges.companyProjects != this.state.companyProjects)) {
        nextState.relevantProjectIds = (isCompanyMode ? Object.keys(nextState.companyProjects || noValue) : [selectedProjectId]);
      }

    this.setState(nextState);
  }

  dispatchBasedOnScope({ company, project, defaultAction, scope = this.props.getNested(['navigationParams', 'scope']) }) {
    switch (scope) {
      case ('company'):
        return typeof company === 'function' ? company() : company;

      case ('project'):
        return typeof project === 'function' ? project() : project;

      default:
        return typeof defaultAction === 'function' ? defaultAction() : defaultAction;
    }
  }

  /*
  || =========================== ||
  ||      Company scope stuff    ||
  || =========================== ||
  */

  getCompanyStateChanges(props, nextProps) {
    const loadEmployeesAndProjectProperties = false;
    const { viewer } = nextProps;
    let newStateChanges = {};
      
    const companiesChanged = props.isValDiff(nextProps, ['companies']);
    const companyIdChanged = props.isValDiff(nextProps, ['navigationParams', 'selectedCompanyId']);
    const scopeChanged = props.isValDiff(nextProps, ['navigationParams', 'scope']);

    // Set viewer
    if (companyIdChanged || props.isValDiff(nextProps, ['viewer']))
      newStateChanges.viewer = nextProps.viewer;

    const nextViewer = newStateChanges.viewer || this.state.viewer || null;
    // Set selectedProjectId // TODO: change project to scope
    if (companyIdChanged || scopeChanged) {
      newStateChanges.menus						  = getDefaultCompanyMenus();
      newStateChanges.selectedProjectId = nextProps.getNested(['navigationParams', 'selectedCompanyId']);
      
      if (nextViewer && (!newStateChanges.selectedProjectId || newStateChanges.selectedProjectId === '_')) 
        newStateChanges.selectedProjectId = nextViewer.companyId;
    }

    
    const nextCompanyId = newStateChanges.selectedProjectId || this.state.selectedProjectId || null;

    // Set project and companyProjects // TODO: change project to scope
    if (nextCompanyId && (companyIdChanged || props.isValDiff(nextProps, ['companies', nextCompanyId]))) {
      DataManagerInstance.loadAndConnect({ subject: SUBJECTS.CONFIGURATIONS, scope: 'companies', scopeId: nextCompanyId, viewer });

      newStateChanges.project = this.formatCompanyToLookLikeProject(nextProps.getNested(['companies', nextCompanyId]));
    }

    if (companyIdChanged || companiesChanged || props.isValDiff(nextProps, ['projects'])) {
      newStateChanges.companyProjects = {};
      Object.entries((nextProps.projects || {}).safeToJS()).forEach(([projectId, projectDetails]) => {
        if (projectDetails.companyId === nextCompanyId && !projectDetails.isDeleted)
          newStateChanges.companyProjects[projectId] = projectDetails;
      });
    }

    if (newStateChanges.companyProjects || newStateChanges.project) {
      const nextCompanyProjects = newStateChanges.companyProjects || this.state.companyProjects || null;
      const nextLang = _.chain(nextCompanyProjects).values().first().get('lang').value();
      const nextProject = newStateChanges.project || this.state.project || null; // TODO: change project to scope
      if (nextLang !== nextProject?.lang) {
        newStateChanges.project = { ...nextProject, lang: nextLang };
      }
    }

    const nextCompany = newStateChanges.project || this.state.project || null; // TODO: change project to scope
    const nextCompanyProjects = newStateChanges.companyProjects || this.state.companyProjects || null;

    // Set projectCompanies // TODO: change project to scope
    if ((companyIdChanged && nextCompanyProjects) || companiesChanged) { // TODO: add check if project changes
      newStateChanges.projectCompanies = {};
      Object.entries((nextProps.companies || {}).safeToJS()).forEach(([companyId, companyDetails]) => {
        Object.entries(companyDetails.projects || {}).forEach(([projectId, projectDetails]) => {
          if (nextCompanyProjects[projectId] && !projectDetails.isDeleted)
            newStateChanges.projectCompanies[companyId] = companyDetails;
        })
      })
    }
   
    // Set projectPermitted // TODO: change project to scope
    if (companyIdChanged)
      newStateChanges.projectPermitted = true;

    // Set projects & detailedProjects
    if ((companyIdChanged && nextCompanyProjects) || companiesChanged) {
      newStateChanges.projects = nextCompanyProjects.setNested([nextCompanyId], nextCompany);
      newStateChanges.detailedProjects = nextCompanyProjects.setNested([nextCompanyId], nextCompany);
    }

    // Set permittedStages & permittedChecklists & permittedChecklistItems
    if (companyIdChanged) {
      newStateChanges.stages = {};
      newStateChanges.checklists = {};
      newStateChanges.checklistItems = {};
    }

    if (companyIdChanged && nextCompanyProjects)
      newStateChanges.projectMembers = this.getCompanyScopeMembers(nextProps, nextCompanyProjects);
       
    // Set companyEmployees
    if (loadEmployeesAndProjectProperties) {
      // Set propertiesSections && propertiesTypes
      if ((companyIdChanged || !this.state.propertiesTypes) && nextCompanyId) { // TODO: add check if changed
        getCompanyPropertiesTypesAndSections(nextCompanyId)
        .then(({ companySubjectSections, companySubjectProperties }) => { this.setState({propertiesSections:companySubjectSections, propertiesTypes: companySubjectProperties}) });
      }

      // Set companyPropertiesMappings
      if ((companyIdChanged || !this.state.propertiesMappings) && nextCompanyId) // TODO: add check if changed
        getCompanyPropertiesMappings(nextCompanyId)
        .then(val => this.setState({propertiesMappings:val}));
        
      // Set propertiesInstancesPerProject && propertiesInstances
      if ((companyIdChanged || !this.state.propertiesInstances) && nextCompanyProjects) { // TODO: add check if changed
        getPropInstancesPerProject(nextCompanyProjects)
        .then(propertiesInstancesPerProject => {
          let propertiesInstances = this.getCompanyPropInstances(propertiesInstancesPerProject);
          this.setState({propertiesInstancesPerProject, propertiesInstances})
        });
      }
    } else {
      newStateChanges = {...newStateChanges, propertiesInstancesPerProject: {}, propertiesInstances: {}, propertiesMappings: {}, propertiesSections: {}, propertiesTypes: {} }
    }

    return newStateChanges;
  }

  getCompanyPropInstances(propInstancesPerProject) {
    let companyPropInstances = {};

    Object.values(propInstancesPerProject).forEach(projectSubjects => {
      Object.entries(projectSubjects).forEach(([subjectName, subjectProps]) => {
        companyPropInstances[subjectName] = { ...(companyPropInstances[subjectName] || {}), ...subjectProps }
      });
    });

    return companyPropInstances;
  }


  formatCompanyToLookLikeProject(company = {}) {
    const { id, name, logo, darkLogo, ...restCompany } = company?.toJS ? company.toJS() : company;
    return { 
      ...restCompany,
      id,
      companyId: id,
      title: name,
      images: {
        main: logo,
        secondary: darkLogo, // FIXME: is it secondary?
      },
    };
  }

  /*
  || =========================== ||
  ||      Project scope stuff    ||
  || =========================== ||
  */

  getProjectStateChanges(props, nextProps) {
    const { viewer } = props;
    const { adminUsers, cleanPermissionBasedData } = nextProps;
    let nextState = {};
    let difProject = !this.didRunProjectStateChangesOnce || props.isValDiff(nextProps, ['navigationParams', 'selectedProjectId']);
    const nextProjectId = nextProps.getNested(['navigationParams', 'selectedProjectId']);

    // MUST STAY AS FIRST CONDITION SO OTHERS CAN RELAY ON nextState.viewer
    if (difProject || viewer != nextProps.viewer) {
      nextState.viewer = getViewer(nextProjectId);
      let viewerGroupsMap = {};
      (nextState.getNested(['viewer','groups'], [])).forEach(g => viewerGroupsMap[g] = true);
      let isCementoTeamViewer = nextState.viewer && adminUsers && adminUsers.getNested([nextState.viewer.id])
      nextState.projectPermitted = isCementoTeamViewer || viewerGroupsMap['0001'] || viewerGroupsMap['0010'] || viewerGroupsMap['0004'];
    }
  
    let projectViewer = nextState.viewer || this.state.viewer;

    if (difProject)
      nextState.selectedProjectId = nextProjectId;
    
    let viewerPermsChanged = false;    
    if (permissionsFunc.isViewerPermissionsChanged(viewer, nextProps.viewer, props.companies, nextProps.companies)) {
      cleanPermissionBasedData(); // TODO: Clean only relevant project data
      viewerPermsChanged = true;
    }

    if (difProject || props.isValDiff(nextProps, ['companies'])) {
      nextState.projectCompanies = this.getProjectCompanies(nextProps, nextProjectId);
    }

    if (difProject || props.isValDiff(nextProps, ['detailedProjects', nextProjectId, 'members']) ||  props.isValDiff(nextProps, ['members']))
      nextState.projectMembers = this.getProjectScopeMembers(nextProps, nextProjectId);

    if (difProject || viewerPermsChanged || props.isValDiff(nextProps, ['stages', nextProjectId])){
      // TODO: Add permissions to backend
      // nextState.stages = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'stages', 'read', nextProps.getNested(['stages', nextProjectId], {}), 'stages', (projectViewer||{}).adminMode).permitted;
      const permittedStages = nextProps.getNested(['stages', nextProjectId], {});
      nextState.stages = Object.entries(permittedStages).reduce((acc, [id, stage]) => _.set(acc, id, new CementoRecordObject(stage)), {}); 
    }

    if (difProject || viewerPermsChanged || props.isValDiff(nextProps, ['checklists', nextProjectId])) {
      const permittedChecklists = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'checklists', 'read', nextProps.getNested(['checklists', nextProjectId], {}), 'checklist', (projectViewer||{}).adminMode).permitted;
      nextState.checklists = Object.entries(permittedChecklists).reduce((acc, [id, checklist]) => _.set(acc, id, new CementoRecordObject(checklist)), {}); 
    }
    
    if (difProject || viewerPermsChanged || props.isValDiff(nextProps, ['checklistItems', nextProjectId])) {
      const permittedChecklistItems = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'checklistItems', 'read', nextProps.getNested(['checklistItems', nextProjectId], {}), 'checklistItem', (projectViewer||{}).adminMode).permitted;
      nextState.checklistItems = Object.entries(permittedChecklistItems).reduce((acc, [id, checklistItem]) => _.set(acc, id, new CementoRecordObject(checklistItem)), {}); 
    }

    if (
      difProject || nextState.viewer || nextState.projectCompanies || viewerPermsChanged || 
      props.isValDiff(nextProps, ['propertiesProject', nextProjectId]) ||
      props.isValDiff(nextProps, ['sectionsProject', nextProjectId]) || 
      props.isValDiff(nextProps, ['propertiesMappings', nextProjectId]) ||
      props.isValDiff(nextProps, ['trades']) ||
      props.isValDiff(nextProps, ['subCategories']) ||
      nextState.projectMembers ||
      nextState.projectCompanies
    ) {
      let result = this.getProjectProperties(nextProps, nextProjectId, nextState.viewer || this.state.viewer);
      nextState.propertiesSections = {};
      Object.entries(result.propertiesSections || {}).forEach(([sectionType, sections]) => {
        nextState.propertiesSections[sectionType] = permissionsFunc.getFilteredMap(projectViewer, nextProjectId, 'propertiesSections', 'read', sections, null, (projectViewer || {}).adminMode).permitted;
      });

      nextState.propertiesTypes = {};
      Object.entries(result.propertiesTypes || {}).forEach(([subjectName, props]) => {
        if (!nextState.propertiesTypes[subjectName])
          nextState.propertiesTypes[subjectName] = {};

        const permittedSectionIds = Object.keys(nextState.propertiesSections[subjectName] || {});
        Object.entries(props).forEach(([propId, prop]) => {
          if (Boolean(prop.sectionId) && !permittedSectionIds.includes(prop.sectionId)) return;
          // Fill in the business type
          // if (prop.businessType == "trades")
          //   debugger;
          if (prop.businessType) {
            prop = fillBusinessType(prop, {
              trades: nextProps.trades,
              companies: nextState.projectCompanies || this.state.projectCompanies,
              members: nextState.projectMembers || this.state.projectMembers,
              subCategories: nextProps.subCategories,
            });
          }
          nextState.propertiesTypes[subjectName][propId] = prop;
        });
      });

      nextState.propertiesMappings = result.propertiesMappings?.safeToJS?.();
    }

    if (difProject || nextState.propertiesTypes || nextState.propertiesMappings) { // With the new Array and complex types, some of the analytics menu items are generated from propertiesTypes
      const state = difProject ? nextState : Object.assign({}, this.state, nextState);
      const defaultMenu = this.buildDefaultProjectMenus(state);

      if (!this.didFetchScopeMenus[nextProjectId]) {
        this.originalProjectMenus[nextProjectId] = {};
        nextState.menus = defaultMenu;
        this.setProjectCombinedMenus(nextProjectId);
      } else {
        nextState.menus = getProjectCombinedMenus({ menus: this.originalProjectMenus[nextProjectId], defaultProjectMenu: defaultMenu });
      }
    }

    if (!this.didRunProjectStateChangesOnce) {
      this.didRunProjectStateChangesOnce = true;
    }
    
    return nextState;
  }

  setProjectCombinedMenus = (projectId, shouldRefetch) => {
    const { viewer } = this.props;

    if (!this.didFetchScopeMenus[projectId] || shouldRefetch) {
      this.didFetchScopeMenus[projectId] = true;

      const onMenus = (menusObject, projectId) => {
        const menus = menusObject?.menu
        this.originalProjectMenus[projectId] = menus;
        const combinedMenus = getProjectCombinedMenus({
          menus,
          defaultProjectMenu: this.buildDefaultProjectMenus(this.state),
        });
        this.setState({ menus: combinedMenus });
      }

      getMenus(projectId, viewer, onMenus)
        .then((menus) => {
          onMenus(menus, projectId)
        })
        .catch(() => {
          this.didFetchScopeMenus[projectId] = false;
        });
    }
  };

  buildDefaultProjectMenus(nextState) {
    nextState = nextState || this.state;
    const formsInfoPropTypes = _.get(nextState, ['propertiesTypes', 'formsInfo']);
    const postsInfoPropTypes = _.get(nextState, ['propertiesTypes', 'postsInfo']);
    const postsInfoPropMappings = _.get(nextState, ['propertiesMappings', 'postsInfo']);
    const projectId = nextState.selectedProjectId;

    return getDefaultProjectMenus({ formsInfoPropTypes, postsInfoPropTypes, postsInfoPropMappings, projectId });
  }

  getCompanyScopeMembers(nextProps, companyProjects) {
    let companyMembers = {};

    Object.keys(companyProjects).forEach(projectId => {
      const projectMembers = this.getProjectScopeMembers(nextProps, projectId);
      companyMembers = { ...companyMembers, ...projectMembers };
    });

    return companyMembers;
  }

  getProjectScopeMembers(nextProps, nextProjectId) {
    let projectMembers = {};
    let membersIds = Object.keys(nextProps.detailedProjects.getNested([nextProjectId, 'members'], {}));
    membersIds.forEach(id => {
      let curr = nextProps.getNested(['members', id]);
      if (curr) {
        let member = curr.toJS ? curr.toJS() : curr;
        let projectScope = member.getNested(['projects', nextProjectId]);
        Object.keys(projectScope || {}).forEach(key => member[key] = projectScope[key]);
        projectMembers[id] = member;
      }
    });

    return projectMembers;
  }

  getProjectCompanies(nextProps, nextProjectId) {
    let projectCompanies = {};
    if (!nextProjectId)
      return projectCompanies;

    
    nextProps.companies.asMutable?.()?.forEach?.((curr) => {
      let projectScope = curr.getNested2(['projects', nextProjectId]);
      if (projectScope && !projectScope.isDeleted) {
        let comp = curr.toJS ? curr.toJS() : curr;  
        Object.keys(projectScope || {}).forEach(key => comp[key] = projectScope[key]);
        projectCompanies[comp.id] = comp;
      }
    });

    return projectCompanies;
  }

  getProjectProperties(nextProps, nextProjectId, viewer) {
    let propsCombineMap = {};
    let sectionsCombineMap = {};
    let propertiesMappings = {};
    const fillMapBySubjectName = (subjectName, list, mapToFill) => {
      if (!mapToFill[subjectName]) 
        mapToFill[subjectName] = {};
      Object.entries(list || {}).forEach(([id,val]) => mapToFill[subjectName][id] = new CementoRecordObject(val));
    }
    Object.entries(nextProps.getNested2(['propertiesProject', nextProjectId], {})).forEach(([subjectName, list]) => fillMapBySubjectName(subjectName, list, propsCombineMap));
    Object.entries(nextProps.getNested2(['sectionsProject', nextProjectId], {})).forEach(([subjectName, list]) => fillMapBySubjectName(subjectName, list, sectionsCombineMap));
    Object.entries(propsCombineMap).forEach(([subjectName, properties]) => propsCombineMap[subjectName] = permissionsFunc.getFilteredMap(viewer, nextProjectId, 'propertiesTypes', 'read', properties, 'property').permitted );
    propertiesMappings = nextProps.getNested2(['propertiesMappings', nextProjectId], {});
    return { propertiesSections: sectionsCombineMap, propertiesTypes: propsCombineMap, propertiesMappings }
  }
  
  render() {
    const { children } = this.props;
    const contextVal = {
      ...this.state,
      setProjectCombinedMenus: this.setProjectCombinedMenus,
    };

    // TODO: Remove all the scope == 'company' and handle all in the relevant functions above
    return (
      <ProjectContext.Provider value={contextVal}>
        {children}
      </ProjectContext.Provider>
    );
  }
}
const noValue = {};
const arrayNoValue = [];
ScopeContextPage = connect(state => ({
  currProjectId: state.ui.currProject,
  viewer: state.users.viewer,
  loading: state.app.loading,
  rtl: state.app.rtl,
  auth: state.auth,
  users: state.users,
  members: state.members.map,
  configurations: state.configurations.map,
  companiesConfigurations: state.configurations.companiesMap,  
  projectLokiLoaded: state.projects.projectLokiLoaded,
  projects: state.projects.map,
  detailedProjects: state.projects.detailsMap,
  trades: state.trades.map,
  titles: state.titles.map,
  stages: state.stages.map,
  checklists: state.checklists.map,
  checklistItems: state.checklistItems.map,
  sectionsProject: state.propertiesTypes.projectSections,
  propertiesMappings: state.propertiesMappings.map,
  propertiesProject: state.propertiesTypes.projectProperties,
  buildings: state.buildings.map,
  floors: state.floors.map,
  units: state.units.map,
  adminUsers: state.quasiStatics.adminUsers,
  subCategories: state.quasiStatics.subCategoriesMap,
}), { cleanPermissionBasedData, startStateLogRequestsListener })(ScopeContextPage);

export default CompaniesHOC(withRouterHOC(ScopeContextPage));
