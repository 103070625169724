import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import { useTree } from '@table-library/react-table-library/tree';
import { ObjectsWrapperManipulationContext } from '../../ObjectsWrapper';
import { getCementoStringValue } from '../../utils';

const VIRTUALIZED_OPTIONS = {
  rowHeight: (_item, _index) => 54,
};

const ObjectsWrapperListViewReactTables = ({ data }) => {
  const { groupBy } = useContext(ObjectsWrapperManipulationContext);

  const { rowData, colDefs } = useMemo(
    () =>
      Object.values(data).reduce(
        (acc, value) => {
          const totalItems = value.data.length;
          let summaryRow = { id: value.id, totalItems };
          summaryRow.nodes = value.data.map((item) => {
            const res = {};
            _.each(item, (value, key) => {
              let processedValue = getCementoStringValue(value);
              if (typeof processedValue === 'string') {
                res[key] = processedValue;
                if (key === groupBy) {
                  summaryRow[key] = processedValue;
                } else {
                  summaryRow[key] = (summaryRow[key] || 0) + 1;
                }
                acc.colDefs.add(key);
              }
            });
            return res;
          });
          acc.rowData.push(summaryRow);
          // acc.rowData = [
          //   ...acc.rowData,
          //   ...value.data.map((item) => {
          //     const res = {};
          //     _.each(item, (value, key) => {
          //       let processedValue = getCementoStringValue(value);
          //       if (typeof processedValue === 'string') {
          //         res[key] = processedValue;
          //         acc.colDefs.add(key);
          //       }
          //     });
          //     return res;
          //   }),
          // ];
          return acc;
        },
        {
          rowData: [],
          colDefs: new Set(),
        }
      ),
    [data]
  );

  const processedColDefs = Array.from(colDefs).reduce((acc, key) => {
    if (key === 'id') return acc;
    const item = {
      label: key,
      renderCell: (item) => {
        const value = item?.[key];
        if (item.totalItems && key !== groupBy) return `${value || 0}/${item.totalItems}`;
        return value;
      },
      tree: key === groupBy,
    };
    if (key === groupBy) {
      acc.unshift(item);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  function onTreeChange(action, state) {
    // console.log(action, state);
  }
  const tableData = { nodes: rowData };

  const tree = useTree(
    tableData,
    {
      onChange: onTreeChange,
    },
    {
      treeIcon: {
        margin: '4px',
        iconDefault: null,
        iconRight: <div>➡️</div>,
        iconDown: <div>⬇️</div>,
      },
    }
  );

  const materialTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns: ${new Array(processedColDefs.length).fill('10%').join(' ')};
    `,
  };

  const theme = useTheme([materialTheme, customTheme]);

  if (!processedColDefs.length || !rowData.length) return null;

  return (
    <CompactTable
      virtualizedOptions={VIRTUALIZED_OPTIONS}
      theme={theme}
      // layout={{ custom: true, horizontalScroll: true }}
      layout={{ isDiv: true, fixedHeader: true, horizontalScroll: true, custom: true }}
      columns={processedColDefs}
      data={tableData}
      tree={tree}
    />
  );
};

// const nodes = [
//   {
//     id: '0',
//     name: 'Shopping List',
//     deadline: new Date(2020, 1, 15),
//     type: 'TASK',
//     isComplete: true,
//     nodes: 3,
//   },
// ];
// const ObjectsWrapperListView = () => {
//   const data = { nodes };

//   const chakraTheme = getTheme(DEFAULT_OPTIONS);
//   const theme = useTheme(chakraTheme);
//   const [ids, setIds] = React.useState([]);

//   const handleExpand = (item) => {
//     if (ids.includes(item.id)) {
//       setIds(ids.filter((id) => id !== item.id));
//     } else {
//       setIds(ids.concat(item.id));
//     }
//   };

//   const COLUMNS = [
//     { label: 'Task', renderCell: (item) => item.name },
//     {
//       label: 'Deadline',
//       renderCell: (item) =>
//         item.deadline.toLocaleDateString('en-US', {
//           year: 'numeric',
//           month: '2-digit',
//           day: '2-digit',
//         }),
//     },
//     { label: 'Type', renderCell: (item) => item.type },
//     {
//       label: 'Complete',
//       renderCell: (item) => item.isComplete.toString(),
//     },
//     { label: 'Tasks', renderCell: (item) => item.nodes?.length },
//   ];

//   const ROW_PROPS = {
//     onClick: handleExpand,
//   };

//   const ROW_OPTIONS = {
//     renderAfterRow: (item) => (
//       <>
//         {ids.includes(item.id) && (
//           <tr style={{ display: 'flex', gridColumn: '1 / -1' }}>
//             <td style={{ flex: '1' }}>
//               <ul
//                 style={{
//                   margin: '0',
//                   padding: '0',
//                   backgroundColor: '#e0e0e0',
//                 }}>
//                 <li>
//                   <strong>Name:</strong> {item.name.toUpperCase()}
//                 </li>
//                 <li>
//                   <strong>Deadline:</strong> {item.deadline.toLocaleDateString('en-US')}
//                 </li>
//                 <li>
//                   <strong>Type:</strong> {item.type}
//                 </li>
//                 <li>
//                   <strong>Complete:</strong> {item.isComplete.toString()}
//                 </li>
//               </ul>
//             </td>
//           </tr>
//         )}
//       </>
//     ),
//   };
//   return (
//     <div style={{ padding: '2rem' }}>
//       <CompactTable columns={COLUMNS} rowProps={ROW_PROPS} rowOptions={ROW_OPTIONS} data={data} theme={theme} />
//     </div>
//   );
// };

export default ObjectsWrapperListViewReactTables;
