import newProjectMessages from '../projects/newProjectMessages';

export const GENERAL_SECTION = {
  'id': 'general',
  'title': {
    'he': 'מידע כללי',
    'en': 'General',
  },
};

export const LOCATION_COLUMNS = [
  {
    key: 'building',
    title: newProjectMessages.locationTypes.buildings,
    'ordinalNo': 0.05,
    'section': GENERAL_SECTION,
    'type': 'Location',
    isVisible: true,
  },
  {
    key: 'floor',
    title: newProjectMessages.locationTypes.floors,
    'ordinalNo': 0.05,
    'section': GENERAL_SECTION,
    'type': 'Location',
    isVisible: true,
  },
  {
    key: 'unit',
    title: newProjectMessages.locationTypes.units,
    'ordinalNo': 0.05,
    'section': GENERAL_SECTION,
    'type': 'Location',
    isVisible: true,
  },
];
