import theme from '../../../../../../web/assets/css/theme';
import toggleRight from '../../../../../../web/assets/img/icons/toggleRight.png';
import Text from '../../../../../../web/components/CementoComponents/Text';

const ExpandableTableCell = ({ cell, getValue }) => {
  const { row, column } = cell;

  const isGroupBy = column.columnDef.groupByIndex === 0 && row.original.subRows;
  const isExpandedGroup = isGroupBy && row.getIsExpanded();
  const value = getValue();

  return (
    <div
      onClick={row.getToggleExpandedHandler()}
      className={`ExpandableTableCell ${
        isGroupBy ? (row.original.index === 0 ? 'primaryGroupByBackground' : 'secondaryGroupByBackground') : ''
      }`}
      style={{
        backgroundColor: isExpandedGroup ? theme.brandPrimary : 'transparent',
        paddingRight: `${row.original.index}rem`,
        cursor: isGroupBy ? 'pointer' : 'auto',
      }}>
      <div className='ExpandableTableCell_content'>
        {isGroupBy ? (
          <div style={{ cursor: 'pointer' }}>
            <img
              className='ExpandableTableCell_arrowIcon'
              src={toggleRight}
              style={{
                transform: row.getIsExpanded() ? 'rotate(90deg)' : 'rotate(180deg)',
              }}
            />
          </div>
        ) : null}
        <div
          className='ExpandableTableCell_value'
          style={{
            width: isGroupBy ? '75%' : '100%',
          }}>
          <Text
            tooltipText={value}
            title={value}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              height: '100%',
              width: '100%',
              textAlign: 'center',
              lineHeight: '40px',
              padding: 0,
            }}>
            {value}
          </Text>
        </div>
        {isGroupBy && row.original?.totalItems >= 0 ? (
          <span
            className='ExpandableTableCell_count'
            style={{
              color: isExpandedGroup ? theme.brandPrimary : 'white',
              backgroundColor: isExpandedGroup ? 'white' : theme.brandPrimary,
            }}>
            {row.original?.totalItems}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default ExpandableTableCell;
