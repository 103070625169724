import { flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import Filter from './Filter';
import { useCallback } from 'react';
import { getCommonCellStyles } from '../util';

const DraggableTableHeader = ({ table, header, headerGroupIndex, className }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });
  const hasChildren = header.column.columnDef.cementoChildren?.length > 0;

  const toggleChildrenHeaders = useCallback(() => {
    if (!hasChildren) return;

    const children = header.column.columnDef.cementoChildren;
    table.setColumnVisibility((state) => {
      let stateCopy = { ...state };
      children.forEach((childId) => {
        stateCopy[childId] = !stateCopy[childId];
      });

      return stateCopy;
    });
  }, [table.setColumnVisibility, hasChildren]);

  const hideColumn = useCallback(
    () => table.setColumnVisibility({ columnId: header.column.id, value: false }),
    [table.setColumnVisibility]
  );

  const style = getCommonCellStyles({ header, column: header.column, isDragging, transform });
  
  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
      className={`DraggableTableHeader ${className}`}>
      <div className='DraggableTableHeader_container' style={header.depth === 1 ? { alignItems: 'start' } : {}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 2rem',
            width: style.width,
          }}>
          <span className='DraggableTableHeader_text'>
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </span>
          <div
            className='DraggableTableHeader_drag-handle cursor-grab'
            {...attributes}
            {...listeners}
            style={{
              padding: '1rem',
              position: 'absolute',
              left: '0',
            }}>
            🟰
          </div>
          {hasChildren ? (
            <div onClick={toggleChildrenHeaders}>
              <span style={{ cursor: 'pointer' }}> ↔️ </span>
            </div>
          ) : null}
        </div>
        {headerGroupIndex === 1 ? (
          <Filter columnId={header.column.id} hideColumn={hideColumn} className='DraggableTableHeader_Filter' />
        ) : null}
        <div
          onDoubleClick={() => header.column.resetSize()}
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={`resizer ${table.options.columnResizeDirection} ${
            header.column.getIsResizing() ? 'isResizing' : ''
          }`}
        />
      </div>
    </th>
  );
};

export default DraggableTableHeader;
