import { createContext, memo, useMemo } from 'react';
import InstancesPreProcessorHOC from './helpers/InstancesPreProcessorHOC';
import useObjectsManipulations from './hooks/useObjectsManipulations';
import useSmartObjects from './hooks/useSmartObjects';
import useProcessedObjects from './hooks/useProcessedObjects';

export const ObjectsWrapperDataContext = createContext({
  data: [],
});

export const ObjectsWrapperManipulationContext = createContext({
  groupByOptions: null,
  filterOptions: null,
  groupBy: null,
  sortBy: null,
  filters: null,
  skip: null,
  step: null,
});

/**
 *
 * @param {import('react').PropsWithChildren<{ objects: any[], propTypes: any, propMapping: any }>} props
 * @returns
 */
export const ObjectsWrapper = (props) => {
  const { subjectType, contentType, propertiesInstances, objects, propTypes, columnVisibility, children } = props;
  const smartObjects = useSmartObjects(objects, propTypes);
  const objectsManipulationsData = useObjectsManipulations({
    objects,
    smartObjects,
    propTypes,
    columnVisibility,
    subjectType,
    contentType,
    propertiesInstances,
  });
  const processedObjects = useProcessedObjects(smartObjects, objectsManipulationsData);

  const memoDataContext = useMemo(() => {
    return {
      data: processedObjects,
      rawObjects: objects,
    };
  }, [processedObjects, objects]);

  return (
    <ObjectsWrapperDataContext.Provider value={memoDataContext}>
      <ObjectsWrapperManipulationContext.Provider value={objectsManipulationsData}>
        {children}
      </ObjectsWrapperManipulationContext.Provider>
    </ObjectsWrapperDataContext.Provider>
  );
};

export default InstancesPreProcessorHOC(memo(ObjectsWrapper));
