import { useMemo } from 'react';
import { filterByQuery } from '../../permissions/funcs';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../../../web/app/constants';
import { getCementoStringValue } from '../utils';

const useProcessedObjects = (objects, options) => {
  const { filters, sortBy, skip, step } = options;

  const filteredObjects = useMemo(() => {
    if (!objects?.length || !filters) {
      return objects;
    }
    // TODO filterByQuery doesnt fully support smart objects, needs small tweaks
    const filteredObjects = filterByQuery(objects, filters.newSearch, FILTER_URL_KEY, FILTER_MENU_PATH_DELIMETER);
    const res = Object.values(filteredObjects);
    return res;
  }, [objects, filters]);

  const sortedObjects = useMemo(() => {
    return sortObjects(filteredObjects, sortBy);
  }, [filteredObjects, sortBy]);

  const paginatedObjects = useMemo(() => {
    if (!sortedObjects?.length || typeof skip !== 'number' || typeof step !== 'number') {
      return sortedObjects;
    }

    return sortedObjects.slice(skip, skip + step);
  }, [sortedObjects, skip, step]);

  return paginatedObjects;
};

const sortObjects = (objects, sortBy) => {
  if (!objects?.length || !sortBy) {
    return objects;
  }

  const sortedObjects = [...objects].sort((a, b) => {
    const aValue = getCementoStringValue(a[sortBy.column]);
    const bValue = getCementoStringValue(b[sortBy.column]);

    if (aValue < bValue) {
      return sortBy.direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortBy.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return sortedObjects;
};

export default useProcessedObjects;
