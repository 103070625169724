import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ObjectsWrapperManipulationContext } from '../ObjectsWrapper';
import FilterMenu from '../../../web/components/CementoComponents/FilterMenu';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../../../web/app/constants';
import TextFilter from '../../../web/views/Posts/TextFilter';
import ViewSelector from '../../../web/components/ViewSelector/ViewSelector';
import GroupsControls from './GroupsControls';
import ColumnVisibilityControls from './ColumnVisibilityControls/ColumnVisibilityControls';
import AddNewButton from '../../../web/components/CementoComponents/AddNewButton';
import systemMessages from '../../app/systemMessages';
import theme from '../../../web/assets/css/theme';
import ExportButton from './ExportButton';
import safetyMessages from '../../safety/safetyMessages';

/**
 *
 * @param {{ viewType?: 'grid' | 'list' }} props
 * @returns
 */
const FilterBar = () => {
  const rtl = useSelector((state) => state.app.rtl);

  const {
    groupBy,
    search,
    viewType,
    filterOptions,
    groupByOptions,
    setFilters,
    setGroupBy,
    setSearch,
    setViewType,
    createNewObject,
    subjectType,
  } = useContext(ObjectsWrapperManipulationContext);

  return (
    <div
      style={{
        display: 'flex',
        [rtl ? 'paddingRight' : 'paddingLeft']: '30%',
        [!rtl ? 'paddingRight' : 'paddingLeft']: '24px',
        alignItems: 'center',
      }}>
      <TextFilter
        containerStyle={{ margin: 12 }}
        defaultValue={search}
        onChange={setSearch}
        clearFilterVal={() => setSearch(null)}
      />
      <GroupsControls value={groupBy} onChange={setGroupBy} groupByOptions={groupByOptions} />
      {viewType === 'table' ? <ColumnVisibilityControls /> : null}
      <FilterMenu
        hideEmptyCategory={true}
        filters={filterOptions}
        filterUrlKey={FILTER_URL_KEY}
        buttonStyle={{ margin: 12 }}
        pathDelimeterOverwrite={FILTER_MENU_PATH_DELIMETER}
        onChange={setFilters}
      />
      <ViewSelector
        key={'PropAnalytics_ViewSelector'}
        activeViewType={viewType}
        onChange={(viewType) => setViewType(viewType)}
      />
      <ExportButton />
      <AddNewButton
        onClick={createNewObject}
        values={{ contentType: safetyMessages.objectsNames[subjectType] }}
        title={systemMessages.addObject}
        style={{
          fontWeight: theme.strongBold,
          margin: `0 ${theme.verticalMargin}px`,
        }}
      />
    </div>
  );
};

export default FilterBar;
