import React, { useCallback, useEffect, useContext, createRef, useMemo, useRef } from "react";
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { connectContext } from 'react-connect-context';
import { v4 as uuidV4 } from 'uuid';
import { ProjectContext } from '../../../common/projects/contexts';
import { putSelectionListOptions, removeSelectionListOption } from '../../../common/propertiesTypes/actions';
import { startToast } from '../../../common/app/actions';
import { compose } from 'recompose';
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import momentTZ from 'moment-timezone';
import Creatable from 'react-select/creatable';
import Selectable, { components as SelectComponents } from 'react-select';
import _ from 'lodash';

import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Image from '../../components/CementoComponents/Image';
import { checkError } from '../../../common/propertiesTypes/funcs';
import { checkPropSettings } from '../../../common/propertiesInstances/funcs.js';
import theme from "../../assets/css/theme";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Text from "./Text";
import radioEmpty from '../../assets/img/icons/radio-empty.png';
import radioFull from '../../assets/img/icons/radio-full.png';
import calendarIcon from '../../assets/img/icons/calendar.png';
import issue_full from '../../assets/img/icons/issue_full.png';
import issue_warn from '../../assets/img/icons/issue_warn.png';
import HomeBlackPNG from '../../assets/img/locations/home_black.png';
import Modal from "../../components/CementoComponents/Modal";
import Buildings from "../../views/Locations/Buildings.js";
import { getFullLocationDetailsByIdNoProps, getLocationTitleNoProps } from '../../../common/locations/func';
import SelectionListHOC from '../../../common/propertiesTypes/SelectionListHOC';

import Tooltip from "./Tooltip";
import Fade from '@material-ui/core/Fade';
import tooltipInfo from "../../assets/img/icons/tooltipInfo.svg";

import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import systemMessages from '../../../common/app/systemMessages';
import TradeBadge from "./TradeBadge";

import FilesDropzone from './FilesDropzone';
import ImportExportExcel from "./ImportExportExcel";
import { InputContext } from "../../../common/functionalInputContext";
import FunctionalInput from "../../../common/app/components/FunctionalInput";
import DynamicUIRenderer from "../../../common/app/components/DynamicUIRenderer";

import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { handleDateInputChange, handleSelectionListChange } from "../../../common/app/standardInputFuncs";
import { isEmptyValue, optionsToText, UTCToLocalTS, localTSToUTC } from "../../../common/app/funcs";
import CardContainer, { CardContainerRow } from "./CardContainer";
import Button from "../../app/standardComponents/Button";
import { useState } from "react";
import EditStrip from "./EditStrip";
import trash from '../../assets/img/icons/trash.png';
import InjectedInputField from "./InputField";
import PostsSelectorHOC from "../../../common/posts/PostsSelectorHOC";
import Posts from "../../views/Posts/Posts";
import { postRowHeight, sectionTitleHeight } from "../../views/Posts/PostsPage";
import reportsMessages from "../../../common/reports/reportsMessages";
import TimeRangePicker from "./TimeRangePicker";
import NoItemsFound from "./NoItemsFound";
import FocusManager from "../FocusManager";
import { safeToJS } from "../../../common/permissions/funcs";
import { DEFAULT_SYSTEM_LANGUAGE } from "../../../common/app/constants";
import checkBrandPrimary from '../../assets/img/icons/check-brand-primary.png';
import defaultUserImage from '../../assets/img/defaultUser.png';
import safetyMessages from '../../../common/safety/safetyMessages';
import useHover from '../../hooks/useHover';
import signatureIcon from '../../assets/img/signature-solid.svg';
import TextFilter from '../../views/Posts/TextFilter';
import ArrayComponent from './ArrayComponent';
import ComplexComponent from './ComplexComponent';
import UploadsComponent from './UploadsComponent';
import ComponentActionButtons from './ComponentActionButtons';
import DrawingMap from '../GMap/components/DrawingMap';
import Marker from '../GMap/components/Marker';
import useCompanies from "../../../common/companies/hooks/useCompanies/index.js";
import PropertiesPostsSelector from "../../views/Properties/ObjectPropertiesPageUI/PropertiesPostsSelector";
import SignatureDialog from "../../../common/images/SignatureDialog.js";

/**
 * @typedef {(valueId: string | null) => void} onCardClickFunc
 * @typedef {{ id: string, defaultMessage: string }} IntlMessage
 * @typedef StandardInputProps
 * @property {any} value
 * @property {boolean} [visible]
 * @property {string | IntlMessage } [title]
 * @property {React.ReactNode[]} [extraComponents]
 * @property {string[]} [error]
 * @property {'table'} [mode]
 * @property {string} [subjectName]
 * @property {boolean} [isExtra] - If it is an extra type of some property
 * @property {(newValue: any) => void} [onChange]
 * @property {propId} [propId]
 * @property {any} [prop] - Cemento property object
 * @property {string} [inputInfo] - Information about the input to be shown as tooltip on hover of the title
 * @property {boolean} [addMode] - Only for Certification type
 * @property {boolean} [titleAsPlaceholder]
 * @property {propertyTypes.STRING | propertyTypes.NUMBER | propertyTypes.BOOLEAN | propertyTypes.DATE | propertyTypes.PDF | propertyTypes.PICTURE | propertyTypes.VIDEO | propertyTypes.CERTIFICATION | propertyTypes.SELECTION_LIST | propertyTypes.DRAWINGS_ARRAY | propertyTypes.FILES_ARRAY | propertyTypes.SIGNATURE | propertyTypes.COMPANIES_DAILY_LOG | propertyTypes.TIME_RANGE | propertyTypes.ARRAY | 'FilesDropzone' | 'Excel'} type
 * @property {string[]} [extraTypes] - Only for Certification type
 * @property {string} [businessType]
 * @property {string} [id]
 * @property {string} [inputKey] - react 'key' of the component
 * @property {boolean} [openPDFInWebPage] - For file input types
 * @property {string | IntlMessage} [placeholder]
 * @property {(fileUri: string, pdfMode: boolean) => void} [onImageSelect]
 * @property {boolean} [alignCenter]
 * @property {boolean} [withResize] - Wether the image / file should be resized or not
 * @property {number} [resizePercent] - How much should the file be resized in percentage (default: 70)
 * @property {boolean} [autoSort] - Auto sort options for SelectionList type
 * @property {boolean} [noBorder] - No border for SelectionList type
 * @property {boolean} [isCreatable] - Allow to create new values for SelectionList type
 * @property {(newValueString: string) => void} [onCreateOption] - For SelectionList type if (isCreatable === true)
 * @property {string} [createLabel] - For SelectionList type if (isCreatable === true). Title for 'create options' button. 
 * @property {{ title: string | IntlMessage }} [tooltip]
 * @property {boolean} [isMulti] - For SelectionList type. Wether you can multi select or not.
 * @property {boolean} [multiple] - For FilesDropzone type. Wether you can multi select or not.
 * @property {number} [maxNumOfFiles] - For FilesDropzone type.
 * @property {string} [accept] - For FilesDropzone, Excel type. What file formats are accepted
 * @property {boolean} [picsOnly]
 * @property {() => ({ fileName: string, data: {} })} [getDataForExport]
 * @property {React.CSSProperties} [containerStyle]
 * @property {React.CSSProperties} [innerStyle]
 * @property {React.CSSProperties} [extraComponentsStyle]
 * @property {React.CSSProperties} [disabledValueStyle]
 * @property {React.CSSProperties} [inputComponentContainer]
 * @property {React.CSSProperties} [valueStyle]
 * @property {React.CSSProperties} [titleStyle]
 * @property {React.CSSProperties} [titleContainerStyle]
 * @property {React.ReactNode} [noValueComponent]
 * @property {(inputRef: React.RefObject<any>, this: object) => void} [onRef]
 * @property {{title: string, id: string}[]} [options] - For SelectionList type
 * @property {{ isArchivable: boolean, accept: string, certificationMonthsTTL: number, isWarning: boolean, isExpiration: boolean }} [settings]
 * @property {boolean} [disabled]
 * @property {string | IntlMessage} [suffix]
 * @property {string | IntlMessage} [prefix]
 * @property {boolean} [isMandatory]
 * @property {onCardClickFunc} [onCardClick]
 * @property {boolean} [isExpandSummary] - will render the expanded form of the input
 * @property {boolean} [noTitle]
 * @property {string} [innerValueId] - In case of array components, will render the input situated at innerValueId
 * @property {() => void} [onDeleteClick]
 * @property {boolean} [isCertificationCanceled] - Did the certification got deactivated
 * 
 */

/**
 * @extends React.Component<StandardInputProps>
 */
class StandardInput extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);

		this.state = {
			key: null,
			values: [],
			error: null,
		};

		this.inputUniqueIdentifier = uuidV4();
	}

	componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	componentWillUnmount() {
		this.onInputRef(null, true);
	}

	setComponentData(props, nextProps) {
		// let newStateChanges = {};


		// if (Object.keys(newStateChanges).length > 0)
		// 	this.setState(newStateChanges);
	}

	handleChange(changedValue, status) {
		const { onChange } = this.props;

		if (onChange)
			onChange(changedValue, status);
	}

	onInputRef = (inputRef, isUnmount) => {
		const { onRef } = this.props;
		const _isUnmount = isUnmount === true;
		if (onRef)
			onRef(
				_isUnmount ? null : inputRef, 
				_isUnmount ? null : this, 
				this.inputUniqueIdentifier,
			);
	}

	checkErrors(value, props) {
		const { prop, propertiesTypes, subjectName, intl, propertiesSections, selectedProjectId } = props || this.props;
		let errors = null;
		if (prop) {
			errors = checkError(value || this.props.value, prop, propertiesSections?.[subjectName] || {}, propertiesTypes ? (propertiesTypes[subjectName] || {}) : {}, intl, selectedProjectId);
			this.setState({ error: errors });
		}
		return errors;
	}

	render() {
		// Please, if you add stuff to the props, remember to document it up there also 🙏
		let {
			value, visible = true, title, extraComponents, error,
			mode, subjectName, isExtra, onChange, propId, prop, inputInfo,
			addMode, intl, lang, titleAsPlaceholder, autoFocus = false, dateFormat,
			isCertificationCanceled
		} = this.props;
		let { rtl, type, extraTypes, businessType, id, inputKey, settings, openPDFInWebPage, placeholder, onImageSelect, alignCenter, withResize, resizePercent, options, autoSort, project, extraTypesProps } = this.props;
		let { noBorder, isCreatable: _isCreatable, isClearable, onCreateOption, isRemovable, createLabel, tooltip = false, isMulti = false, multiple = false, maxNumOfFiles, accept, picsOnly, getDataForExport, label, isMandatory, allowBlank } = this.props;
		let { containerStyle, innerStyle, extraComponentsStyle, disabledValueStyle, inputComponentContainer = {}, valueStyle = {}, titleStyle = {}, titleContainerStyle = {}, noValueComponent, noValueComponentParams, noValueComponentValueStyle } = this.props;
		const { shouldReturnFullLocation, withTooltip, isTableCell } = this.props;
		const {
			prefix, suffix, UIStruct,
			isExpandSummary, innerValueId, onDeleteClick,
			isReadOnly, UIStructContainerStyle, isInFocus,
			multiline, renderBuildingDirectly, onCardClick,
			renderPreview,
		} = this.props;

		isMandatory = Boolean(isMandatory || (prop && prop.isMandatory));
		
		value = propertyTypes.normalizeInputValue(type, value);

		const disabled = this.props.disabled || isReadOnly;

		const key = inputKey || this.props.key;
		const isCreatable = _isCreatable || prop && prop.getNested(['isCreatable']);

		let noTitle = Boolean(this.props.noTitle);
		let inputComponent = null;
		if (titleAsPlaceholder && !placeholder)
			placeholder = title;
		let intlPlaceholder = (placeholder && typeof placeholder === 'object' && placeholder.defaultMessage) ? intl.formatMessage(placeholder) : (placeholder);
		if (title && _.has(title, 'id') && _.has(title, 'defaultMessage'))
			title = intl.formatMessage(title);
		if (!visible)
			return <></>;

		disabledValueStyle = disabledValueStyle || valueStyle;

		if (type === propertyTypes.CERTIFICATION)
			return (
				<InjectedInputField
					id={id}
					type={type}
					name={title}
					onChange={(newVal) => this.handleChange(newVal)}
					value={value}
					addMode={addMode}
					autoFocus={autoFocus}
					disabled={disabled}
					businessType={businessType}
					error={error}
					extraComponents={extraComponents}
					isMulti={isMulti}
					isMandatory={isMandatory}
					mode={mode}
					noBorder={noBorder}
					settings={settings}
					placeholder={placeholder}
					propId={propId}
					options={options}
					originalOnRef={this.props.onRef}
					onInputRef={this.onInputRef}
					extraTypes={extraTypes}
					extraTypesProps={extraTypesProps}
					subjectName={subjectName}
					visible={visible}
					style={containerStyle}
					titleStyle={titleStyle}
					extraComponentsStyle={extraComponentsStyle}
					accept={accept}
					alignCenter={alignCenter}
					autoSort={autoSort}
					createLabel={createLabel}
					disabledValueStyle={disabledValueStyle}
					getDataForExport={getDataForExport}
					innerStyle={innerStyle}
					inputComponentContainer={inputComponentContainer}
					inputKey={inputKey}
					inputInfo={inputInfo}
					isCreatable={isCreatable}
					isExtra={isExtra}
					maxNumOfFiles={maxNumOfFiles}
					multiple={multiple}
					noValueComponent={noValueComponent}
					onImageSelect={onImageSelect}
					picsOnly={picsOnly}
					openPDFInWebPage={openPDFInWebPage}
					prefix={prefix}
					suffix={suffix}
					prop={prop}
					resizePercent={resizePercent}
					titleAsPlaceholder={titleAsPlaceholder}
					titleContainerStyle={titleContainerStyle}
					tooltip={tooltip}
					valueStyle={valueStyle}
					withResize={withResize}
					allowBlank={allowBlank}
					onCreateOption={onCreateOption}
					isCertificationCanceled={isCertificationCanceled}
				/>
			);

		switch (type) {
			case 'Link': { break; }
			case propertyTypes.NUMBER:
			case propertyTypes.STRING: {
				inputComponent = <InputsComponent
					disabledValueStyle={disabledValueStyle}
					valueStyle={valueStyle}
					style={innerStyle}
					mode={mode}
					type={type}
					value={value}
					isMultiline={multiline}
					placeholder={intlPlaceholder}
					disabled={disabled}
					onChange={this.handleChange}
					autoFocus={autoFocus}
				/>;
				break;
			}
			case propertyTypes.LOCATION: {
				inputComponent = <LocationPickerComponent
					rtl={rtl}
					intl={intl}
					disabledValueStyle={disabledValueStyle}
					style={innerStyle}
					placeholder={intlPlaceholder}
					mode={mode}
					key={key}
					value={value}
					shouldReturnFullLocation={shouldReturnFullLocation}
					isDisabled={disabled}
					onChange={this.handleChange}
					renderBuildingDirectly={renderBuildingDirectly}
					multiple={settings?.multiple}
					filterType={settings?.filterType}
				/>;
				break;
			}
			case propertyTypes.SELECTION_LIST: {
				if (businessType === 'members') {
					noTitle = mode === 'modal';
					inputComponent = (
						<MembersSelectionComponent
							onChange={this.handleChange}
							settings={settings}
							value={value}
							title={title}
							propId={propId}
							mode={mode}
							isExpanded={isExpandSummary}
							onCardClick={onCardClick}
							isDisabled={disabled}
							valueStyle={valueStyle}
							disabledValueStyle={disabledValueStyle}
							key={key}
							placeholder={intlPlaceholder}
							innersStyle={innerStyle}
							autoFocus={autoFocus}
							lang={lang}
							noBorder={noBorder}
							isMulti={isMulti || settings?.isMulti}
						/>
					);
					break;
				} else if(businessType === 'posts') {
					noTitle = settings?.noHeader
					inputComponent = (
						<PropertiesPostsSelector
							isDisabled={disabled}
							isSafetyPostsOnly={settings?.isSafetyPostsOnly}
							postsType={settings?.postsType}
							onChange={this.handleChange}
							openSideCard={(type, cardProps, ComponentToRender) => onCardClick('', [type, cardProps, ComponentToRender])}
							selectedPosts={value}
						
						></PropertiesPostsSelector>
					);
					break;
				}

				inputComponent = <SelectComponent
					autoSortOptions={autoSort}
					disabledValueStyle={disabledValueStyle}
					valueStyle={valueStyle}
					style={innerStyle}
					placeholder={intlPlaceholder}
					businessType={businessType}
					mode={mode}
					key={key}
					value={value}
					isMulti={isMulti || settings?.isMulti}
					options={options}
					disabled={disabled}
					noBorder={noBorder}
					onChange={this.handleChange}
					isCreatable={isCreatable}
					isClearable={isClearable}
					isRemovable={isRemovable}
					onCreateOption={onCreateOption}
					createLabel={createLabel}
					lang={lang}
					subjectName={subjectName}
					project={project}
					propId={propId}
				/>;
				break;
			}
			case propertyTypes.PICTURE:
			case propertyTypes.VIDEO:
			case propertyTypes.PDF:
			case propertyTypes.FILES_ARRAY:
			case propertyTypes.DRAWINGS_ARRAY: {

				let uploadSettings = _.assign({}, settings);
				if(accept){
					uploadSettings.accept = accept
				}

				inputComponent = <UploadsComponent
					disabledValueStyle={disabledValueStyle}
					settings={uploadSettings}
					style={innerStyle}
					rtl={rtl}
					type={type}
					mode={mode}
					isExtra={isExtra}
					inputKey={inputKey}
					immediateUpload={settings?.immediateUpload}
					multiple={settings?.multiple !== undefined ? settings?.multiple : (type === 'DrawingsArray' || type === 'FilesArray')}
					value={(Array.isArray(value) ? value : value ? [value] : []) || []}
					onChange={this.handleChange}
					extraComponent={this.setExtraComponent}
					disabled={disabled}
					withResize={withResize}
					resizePercent={resizePercent}
					onImageSelect={onImageSelect}
					openPDFInWebPage={openPDFInWebPage}
					noValueComponentValueStyle={noValueComponentValueStyle}
					onCardClick={onCardClick}
					renderPreview={renderPreview}
					maxNumOfFiles={maxNumOfFiles}
					disabledDisplayType={prop?.defaultContentType === 'drawing' ? 'pdf' : undefined}
				/>;
				break;
			}
			case propertyTypes.DATE: {
				inputComponent = <DateComponent
					disabledValueStyle={disabledValueStyle}
					valueStyle={valueStyle}
					style={innerStyle}
					rtl={rtl}
					type={type}
					settings={settings}
					value={value}
					mode={mode}
					intl={intl}
					placeholder={intlPlaceholder || intl.formatMessage(propertiesMessages.datePlaceholder)}
					disabled={disabled}
					dateFormat={dateFormat}
					onChange={this.handleChange}
					position={settings?.position}
					withTooltip={withTooltip}
					isTableCell={isTableCell}
				/>;
				break;
			}
			case propertyTypes.BOOLEAN: {
				inputComponent = <BooleanComponent
					disabledValueStyle={disabledValueStyle}
					valueStyle={valueStyle}
					placeholder={intlPlaceholder}
					style={innerStyle}
					rtl={rtl}
					value={value}
					mode={mode}
					type={type}
					disabled={disabled}
					settings={settings}
					onChange={this.handleChange} />;
				break;
			}
			case propertyTypes.CERTIFICATION: {
				inputComponent = <CertificationComponent
					{...this.props}
					lang={lang}
					extraTypes={extraTypes}
					withResize={withResize}
					subjectName={subjectName}
					resizePercent={resizePercent}
					onImageSelect={onImageSelect}
					style={innerStyle}
					addMode={addMode}
					rtl={rtl}
					settings={settings}
					value={value}
					mode={mode}
					intl={intl}
					disabled={disabled}
					onChange={this.handleChange} 
					isCertificationCanceled={isCertificationCanceled}
					/>;
				break;
			}
			case 'FilesDropzone': {
				inputComponent = <FilesDropzone
					rtl={rtl}
					multiple={multiple}
					key={key}
					id={id}
					value={Array.isArray(value) ? value : value ? [value] : []}
					maxNumOfFiles={maxNumOfFiles || 10}
					accept={accept || '.png,.jpg,.jpeg'}
					disabled={disabled}
					picsOnly={picsOnly}
					onChange={this.handleChange}
				/>;
				break;
			}
			case 'Map': {
				const markerPosition =
					value?.coords?.latitude && value?.coords?.longitude
						? { lat: value?.coords?.latitude, lng: value?.coords?.longitude }
						: null;
				const handleChange = marker => {
					const { position, latLng } = marker;

					const payload = {
						coords: {
							// "position" is accesible on "create" marker obj
							// "latLng" is accesible on "edit" marker obj
							latitude: (position || latLng).lat(),
							longitude: (position || latLng).lng(),
						},
						radiusInMeters: 100,
					};
					this.handleChange(payload);
				};
				
				inputComponent =
					disabled && !markerPosition ? null : (
						<DrawingMap
							id={id}
							key={key}
							drawingMode={markerPosition ? null : 'marker'}
							center={markerPosition}
							autocompleteProps={{
								visible: !disabled,
								onPlaceChanged: handleChange,
							}}
							drawingManagerProps={{
								onMarkerComplete: handleChange,
								options: {
									drawingControlOptions: {
										drawingModes: markerPosition ? [] : ['marker'],
									},
									markerOptions: {
										draggable: true,
									},
								},
							}}
						>
							{markerPosition ? (
								<Marker draggable={!disabled} position={markerPosition} onDragEnd={handleChange} />
							) : null}
						</DrawingMap>
					);
				break;
			}
			case propertyTypes.SIGNATURE: {
				inputComponent = (
					<SignatureComponent 
						selectedProjectId={this.props.selectedProjectId}
						onChange={this.handleChange}
						disabled={disabled}
						value={value}
						settings={settings}
						style={valueStyle}
						placeholder={intlPlaceholder}
						valueStyle={valueStyle}
					/>
				)
				break;
			}

			case 'Excel': {
				inputComponent = <ImportExportExcel
					label={label}
					key={key}
					id={id}
					accept={accept || '.xlsx'}
					disabled={disabled}
					rtl={rtl}
					onChange={onChange}
					style={innerStyle}
					mode={mode || {}}
					getDataForExport={getDataForExport}
					settings={settings}
				/>;
				break;
			}

			// Only working properly when in modal or sidecard
			case (propertyTypes.ARRAY): {
				noTitle = true;
				inputComponent = (
					<ArrayComponent
						title={title}
						onRef={this.props.onRef}
						onChange={this.handleChange}
						onCardClick={onCardClick}
						propId={propId}
						subjectName={subjectName}
						value={value}
						isDisabled={disabled}
						isExpanded={isExpandSummary}
						innerValueId={innerValueId}
						settings={settings}
          />
				);
				break;
			}

			// Only working properly when in modal or sidecard
			case (propertyTypes.COMPLEX): {
				inputComponent = (
					<div style={{ paddingTop: theme.padding }}>
						<ComplexComponent
							onRef={this.props.onRef}
							title={title}
							onChange={this.handleChange}
							propId={propId}
							onCardClick={onCardClick}
							subjectName={subjectName}
							value={value}
							isDisabled={disabled}
							onDeleteClick={onDeleteClick}
							isFocus={isInFocus}
						/>
					</div>
				);
				break;
			}

			case (propertyTypes.TIME_RANGE): {
				inputComponent = (
					<TimeRangePicker
						onChange={this.handleChange}
						isDisabled={disabled}
						value={value}
						separator={systemMessages.to}
						noValueComponentValueStyle={noValueComponentValueStyle}
					/>
				);

				break;
			}


			default: {
				inputComponent = null;
			}
		}

		if (UIStruct && !isExpandSummary)
			inputComponent = (
				<div
					style={Object.assign(
						{ cursor: onCardClick ? 'pointer' : 'auto' },
						// isInFocus && { backgroundColor: theme.innerCollapsibleRowSelectedBackground/* , border: `1px solid ${theme.innerCollapsibleBrandPrimaryBorders}` */ }, 
						UIStructContainerStyle
					)}
					onClick={() => onCardClick && onCardClick(prop)}
				>
					<DynamicUIRenderer UIStruct={UIStruct} />
				</div>
			);

		let mainStyle = { display: 'flex', flex: 1, flexDirection: mode == 'table' ? 'row' : 'column', padding: 5, justifyContent: 'center', alignItems: mode == 'table' ? 'center' : null };
		if (containerStyle)
			mainStyle = Object.assign(mainStyle, containerStyle);

		const tooltipFont = theme.mediumFontSize;
		const LightTooltip = withStyles(theme => ({
			tooltip: {
				backgroundColor: theme.palette.common.white,
				color: 'rgba(0, 0, 0, 0.87)',
				boxShadow: theme.shadows[1],
				fontSize: tooltipFont,
			},
		}))(Tooltip);

		const textErrorStyle = error && isMandatory ? { color:'red' } : {}

		return (
			<div id={id} style={mainStyle} ref={this.onInputRef} title={inputInfo}>
				{Boolean(title && !noTitle) &&
					<div style={{ marginBottom: (UIStruct ? theme.margin - 5 : 0), transition: "all 150ms ease 0s", color: (error && !disabled) ? 'red' : theme.headerColorDark, display: 'flex', justifyContent: 'space-between', textAlign: alignCenter ? 'right' : 'none', ...((mode || {}).labelStyle), ...titleContainerStyle }}>
						<div style={{ display: 'flex' }}>
							{Boolean(!disabled && isMandatory) && <Text>*</Text>}
							<Text style={Object.assign({ color: theme.brandNeutral }, titleStyle,textErrorStyle)} title={(error || []).join(', ')}>{prefix}{title}{suffix}</Text>
							{Boolean(tooltip) && (
								<LightTooltip
									interactive
									leaveDelay={500}
									title={(tooltip || {}).title}
									placement={rtl ? 'left' : 'right'}
									TransitionComponent={Fade}
								>
									<img
										src={tooltipInfo}
										style={{ [rtl ? 'marginRight' : 'marginLeft']: theme.verticalMargin, height: '12px', opacity: '80%', alignSelf: 'center' }}
									/>
								</LightTooltip>
							)}
						</div>
					</div>
				}

				{disabled && _.isNil(value) && noValueComponent
					? noValueComponent
					: (
						<>
							<div style={{ flex: 1, ...inputComponentContainer }}>{inputComponent}</div>
							{Boolean(extraComponents) && <div style={extraComponentsStyle}>{extraComponents}</div>}
						</>
					)}
			</div>
		);
	}
}

let styles = {
};

const enhance = compose(
	connectContext(ProjectContext.Consumer),
	connectContext(InputContext.Consumer),
	withStyles(styles),
	injectIntl,
	connect(state => ({
		rtl: state.app.rtl,
	}), { putSelectionListOptions })
);

StandardInput = enhance(StandardInput);
export default StandardInput;

const MembersSelectionComponent = (props) => {
	const { members, titles } = useSelector(state => ({
		members: state.members.map,
		titles: state.titles.map,
	}));
	const { companies } = useCompanies()

	const { project } = useContext(ProjectContext);
	const { 
		onChange, settings, value, isExpanded, title, propId, onCardClick, isDisabled, mode, 
		autoSort, disabledValueStyle, valueStyle, innerStyle, placeholder, noBorder, lang, isMulti: propsIsMulti,
	} = props;
	const [values, setValues] = useState([]);
	const [filterVal, setFilterVal] = useState('');

	useEffect(() => {
		setValues(getValues());
	}, [members, companies, filterVal]);

	const getValues = useCallback(() => {
		const projLang = (project || {}).getNested(['lang'], DEFAULT_SYSTEM_LANGUAGE);
		const projectMembers = (project || {}).members;
		const _members = safeToJS(members) || {};

		let newValues = [];
		_.values(safeToJS(projectMembers)).forEach(member => {
			member = _members[_.get(member, 'id')];

			if (!_.get(member, 'id'))
				return;

			const { id, displayName, avatar, companyId } = member;
			const titleId = _.get(member, ['projects', project?.id, 'title'], member.title);

			const valueTitle = displayName || '';
			if (filterVal && valueTitle.trim().toLowerCase().indexOf(filterVal.trim().toLowerCase()) === -1)
				return;

			let value = {
				id,
				title: { [projLang]: valueTitle },
				image: avatar ? { uri: avatar } : { uri: defaultUserImage },
			};

			let subTitleString = '';
			if (titleId) {
				const titleString = titles.getNested([titleId, 'getTitle']);
				if (titleString)
					subTitleString = titleString;
			}

			if (companyId) {
				const companyName = (companies || {}).getNested([companyId, 'name']);
				if (companyName)
					subTitleString = subTitleString ? `${subTitleString}, ${companyName}` : companyName;
			}

			if (subTitleString)
				value.subTitle = { [projLang]: subTitleString };

			newValues.push(value);
		});

		return newValues;
	}, [members, companies, project, filterVal]);

	const handleFilterChange = useCallback(val => {
		setFilterVal(val || '');	
	});

	const showAsSimpleSelectInput = mode !== 'modal';
	if (showAsSimpleSelectInput) {
		return (
			<SelectComponent
				autoSortOptions={autoSort}
				disabledValueStyle={disabledValueStyle}
				valueStyle={valueStyle}
				style={innerStyle}
				placeholder={placeholder}
				businessType={propertyTypes.BUSINESS_TYPES.members}
				mode={mode}
				value={value}
				isMulti={propsIsMulti}
				options={values}
				disabled={isDisabled}
				noBorder={noBorder}
				onChange={onChange}
				isClearable
				lang={lang}
			/>
		)
	}

	const cardContainerId = propId + title + '_membersSelection';
	return (
		<FocusManager.Element scrollToElementOnFocusWithin elementId={cardContainerId}>
			{focusProps => (
				<SelectionListHOC onChange={onChange} settings={settings} value={value} values={values}>
					{({ isMulti, onChange: onInputChange, viewType, selectedValueDefinitionsMap, simpleValues }) => {
						const selectedValueDefinitions = _.values(selectedValueDefinitionsMap);

						if (isExpanded)
							return (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
	            		<TextFilter 
										containerStyle={{ margin: 0, marginBottom: theme.margin, borderRadius: '5px', backgroundColor: 'transparent' }} 
										onChange={handleFilterChange} 
										clearFilterVal={() => handleFilterChange('')} 
									/>

									<CardContainer
										title={title}
										counterLabel={safetyMessages.employees}
										counterCount={selectedValueDefinitions.length}
										mainContainerStyle={{ marginBottom: 0, cursor: 'pointer' }}
										isSelected={focusProps.isFocus}
										isFocused={focusProps.isFocusWithin}
									>
										{simpleValues.map(valueDef => (
											<CardContainerRow key={`rich-value-expanded-${cardContainerId}-${valueDef.id}`}>
												<RichSelectionListValue 
													withCheckbox
													isDisabled={isDisabled}
													onSelect={() => onInputChange(valueDef.id)}
													title={valueDef.title}
													subTitle={valueDef.subTitle}
													image={valueDef.image.uri}
													isSelected={_.get(value, [valueDef.id])}
												/>
											</CardContainerRow>
										))}
									</CardContainer>
								</div>
							);

						return (
							<CardContainer
								title={title}
								counterLabel={safetyMessages.employees}
								counterCount={selectedValueDefinitions.length}
								mainContainerStyle={{ marginBottom: 0, cursor: 'pointer' }}
								isSelected={focusProps.isFocus}
								isFocused={focusProps.isFocusWithin}
								footerContainerStyle={{ display: 'flex', justifyContent: 'center' }}
								onClick={() => onCardClick && onCardClick()}
							>
								{selectedValueDefinitions.map(valueDef => (
									<div onClick={() => onCardClick && onCardClick()} key={`rich-value-${cardContainerId}-${valueDef.id}`}>
										<CardContainerRow doPropagate>
											<RichSelectionListValue
												title={valueDef.title}
												subTitle={valueDef.subTitle}
												image={_.get(valueDef, ['image', 'uri'])}
												style={{ cursor: 'pointer' }}
											/>
										</CardContainerRow>
									</div>
								))}
							</CardContainer>
						);
					}}
				</SelectionListHOC>
			)}
		</FocusManager.Element>
	);
};

const RichSelectionListValue = (props) => {
	const { title, subTitle, image, onSelect, isSelected, isDisabled, withCheckbox, style } = props;

	return (
		<div onClick={(!isDisabled && onSelect) ? onSelect : undefined} style={Object.assign({}, richSelectionListValueStyles.mainContainerStyle, { cursor: !isDisabled && onSelect ? 'pointer' : 'default' }, style)}>
			{Boolean(withCheckbox) && (
				<Checkbox style={richSelectionListValueStyles.checkbox} isChecked={isSelected} onCheck={onSelect} isDisabled={isDisabled} />
			)}

			<div style={richSelectionListValueStyles.mainContent}>
				{Boolean(image) && <div style={richSelectionListValueStyles.imageContainer}><img src={image} style={richSelectionListValueStyles.image} /></div>}
				<div style={richSelectionListValueStyles.titlesContainer}>
					<Text style={richSelectionListValueStyles.title}>{title}</Text>
					{Boolean(subTitle) && <Text>{subTitle}</Text>}
				</div>
			</div>
		</div>
	);
};
const IMAGE_SIZE = 45;

const richSelectionListValueStyles = {
	mainContainerStyle: {
		display: 'flex',
		alignItems: 'center',
	},

	mainContent: {
		display: 'flex',
		alignItems: 'center',
	},
	checkbox: {
		// marginInline: theme.margin / 3,
	},
	image: {
		height: '100%',
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 50,
		height: IMAGE_SIZE,
		width: IMAGE_SIZE,
		marginInline: theme.margin,
		overflow: 'hidden',
	},
	titlesContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	title: {
		fontSize: theme.fontSizeH6,
	}
};

const Checkbox = (props) => {
	const { isChecked, isDisabled, onCheck, size = 30, style } = props;

	return (
		<div onClick={(!isDisabled && onCheck) ? onCheck : undefined} style={Object.assign({ height: size, width: size, cursor: !isDisabled && onCheck ? 'pointer' : 'auto' }, checkboxStyles.mainContainer, Boolean(isChecked) && checkboxStyles.mainContainerActive, style)}>
			{Boolean(isChecked) && (
				<img style={{ height: size / 2.7 }} src={checkBrandPrimary} />
			)}
		</div>
	);
};

const checkboxStyles = {
	mainContainer: {
		borderColor: theme.placeholderTextColor,
		borderRadius: theme.circleBorderRadius,
		borderWidth: theme.thinBorderWidth,
		borderStyle: 'solid',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	mainContainerActive: {
		borderColor: theme.brandPrimary,
	},
};














/**
 * @typedef PostsSelectorComponentProps
 * @property {(newValue: import('../../../common/posts/PostsSelectorHOC').PostsSelectorValue) => void} onChange
 * @property {import('../../../common/posts/PostsSelectorHOC').PostsSelectorValue} value
 * @property {boolean} [expandAll]
 * @property {boolean} [isExpanded]
 * @property {boolean} isDisabled
 * @property {() => void} [onSelectPostClick]
 * @property {() => void} [onCreatePostClick]
 * @property {(post: any) => void} [onPostClick]
 * @property {{ [postId: string]: string }} [currViewPost] - Post id of a selected post (selected as in viewing, not input)
 * 
 * @param {PostsSelectorComponentProps} props
 * @returns
 */
export const PostsSelectorComponent = (props) => {
	const {
		onChange, value, expandAll,
		isExpanded, isDisabled, settings,
		onSelectPostClick, onCreatePostClick,
		onPostClick, currViewPost,
	} = props;
	const ref = useRef();

	const handleSelectPostClick = useCallback(() => {
		if (onSelectPostClick)
			onSelectPostClick();
	}, [onSelectPostClick]);

	const handleCreatePostClick = useCallback(() => {
		if (onCreatePostClick)
			onCreatePostClick();
	}, [onCreatePostClick]);

	const handleViewPostClick = useCallback((post) => {
		if (!_.get(post, ['id']))
			return;

		if (onPostClick)
			onPostClick(post);
	}, [onPostClick]);

	return (
		<PostsSelectorHOC onChange={onChange} value={value} settings={settings} isExpanded={isExpanded}>
			{({ filteredPosts, onPostSelect, selectedPostsArr, value, postsType, isSafety }) => {
				const postConstantProps = { 
					height: 'auto',
					width: '100%', 
					expandAll, 
					rowHeight: postRowHeight, 
					postsPerRow: 1, 
					sortDirection: 1, 
					groupBy: { key: 'createdAt', valuePath: ['createdAt'], isDate: true, isSafety } ,
					isLocationMode: false,
				};
				
				const createButtonTitle = reportsMessages.buttons.create[postsType] ? reportsMessages.buttons.create[postsType] : null;
				const selectButtonTitle = reportsMessages.buttons.select[postsType] ? reportsMessages.buttons.select[postsType] : reportsMessages.buttons.select['all'];
				const ctnStyle = {
					width: '100%',
					height: `calc(${theme.popupHeight} - ${theme.headerHeight}px - ${theme.paddingSize * 6}px - ${theme.fontSizeH4}px)`,
					overflow: 'auto',
				};

				if (isExpanded)
					return (
						<div ref={ref} style={ctnStyle}>
							<Posts
								{...postConstantProps}
								sectionTitleHeight={sectionTitleHeight}
								selectedPosts={value}
								postsArray={filteredPosts}
								onPostSelect={!isDisabled && onPostSelect}
								scrollRef={ref.current}
							/>
						</div>
					);

				if (isDisabled && !selectedPostsArr.length)
					return <NoItemsFound compStyles={{ height: theme.headerHeight }} />;

				return (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{Boolean(selectedPostsArr.length) && (
							<Posts
								{...postConstantProps}
								isNoGroups
								fullWidthPosts
								postsArray={selectedPostsArr}
								selectedPosts={currViewPost}
								onPostSelect={handleViewPostClick}
								scrollRef={ref.current}
							/>
						)}
						{Boolean(!isDisabled) &&
							<ComponentActionButtons
								isBoxed={!selectedPostsArr.length}
								buttons={[
									{ type: 'select', onClick: handleSelectPostClick, title: selectButtonTitle },
									{ type: 'add', onClick: handleCreatePostClick, title: createButtonTitle },
								]}
							/>
						}
					</div>
				);
			}}
		</PostsSelectorHOC>
	);
};





class InputsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onStringChange = this.onStringChange.bind(this);
		this.state = { focused: false };
	}

	onStringChange(e) {
		const { onChange, type } = this.props;
		let inputType = type == 'String' ? 'text' : 'number';

		let newVal = e.target.value;
		if (inputType == "number") {
			if (newVal && isNaN(newVal)) return;
			else if (isEmptyValue(newVal)) newVal = null;
			else newVal = parseInt(newVal);
		}

		if (onChange)
			onChange(newVal);
	}

	stringValidator = ((obj) => {
		return toString.call(obj) == '[object String]';
	});

	onInput = (e) => {
		if (!this.props.isMultiline)
			return;

		const textArea = e.target;
		textArea.style.height = 'auto';
		textArea.style.height = textArea.scrollHeight + 'px';
	}

	render() {
		const { value, placeholder, disabled, type, mode, style = {}, disabledValueStyle = {}, valueStyle = {}, isMultiline } = this.props;
		const { focused } = this.state;
		if (mode == "table")
			return <Text style={{ fontWeight: theme.noBold, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', ...style }} enableTitle={true}>{value}</Text>;

		if (disabled)
			return (
				<ValueComponent
					valueText={isEmptyValue(value) ? '' : String(value)}
					defaultText={propertiesMessages.empty}
					style={disabledValueStyle}
				/>
			);

		let emptyValue = Number.isInteger(value) ? 0 : '';
		return (<>
			<div style={{ display: 'flex', flex: 1, paddingBottom: 8, paddingTop: 8, borderBottomColor: '#d2d2d2', borderBottomWidth: 1, borderBottomStyle: 'solid', ...style, ...Boolean(focused) && { borderBottomColor: theme.brandPrimary, borderBottomWidth: 2, borderBottomStyle: 'solid' } }}>
				{React.createElement(
					isMultiline ? 'textarea' : 'input',
					Object.assign(
						{
							onFocus: () => this.setState({ focused: true }),
							onBlur: () => this.setState({ focused: false }),
							disabled: disabled,
							value: value || emptyValue,
							placeholder: placeholder,
							type: 'text',
							onChange: this.onStringChange,
							style: Object.assign({ 
								flex: 1, 
								fontSize: theme.fontSize, 
								...valueStyle, 
								WebkitAppearance: 'textfield',
							}, isMultiline && { 
								resize: 'none', 
								borderWidth: 0, 
								overflow: 'hidden', 
								background: 'none', 
								fontFamily: 'inherit',
								fontSize: 'inherit',
							}),
						},
						isMultiline && {
							rows: 1,
							onInput: this.onInput,
						}
					)
				)}
			</div>
		</>
		);
	}
}








































class BooleanComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onBoolChange = this.onBoolChange.bind(this);
	}

	onBoolChange(newVal) {
		const { onChange, value } = this.props;
		//let newVal = !Boolean(value);
		if (onChange)
			onChange(newVal);
	}

	render() {
		const { value, disabled, mode, rtl, style = {}, type, intlPlaceholder, intl, disabledValueStyle = {}, valueStyle = {}, settings } = this.props;
		const iconSize = 16;

		let valueToShow = value;
		if ((valueToShow == null || valueToShow == undefined) && settings && settings.hasOwnProperty('defaultVal'))
			valueToShow = settings.defaultVal;
		valueToShow = Boolean(valueToShow);

		if (disabled)
			return (
				<InputsComponent
					disabledValueStyle={disabledValueStyle}
					style={style}
					mode={mode}
					type={'String'}
					value={intl.formatMessage(valueToShow ? systemMessages.yes : systemMessages.no)}
					placeholder={intlPlaceholder}
					disabled={disabled} />
			);

		return (
			<div style={{ display: 'flex', ...style }}>
				<div style={{ display: 'flex', alignItems: 'center', cursor: disabled ? 'default' : 'pointer', [rtl ? 'marginLeft' : 'marginRight']: 5 }} onClick={disabled ? undefined : (() => this.onBoolChange(true))}>
					<div><img src={(valueToShow) ? radioFull : radioEmpty} /></div>
					<Text style={{ padding: 5, color: valueToShow ? theme.headerColorDark : theme.brandInfo, ...valueStyle }}>{systemMessages.yes}</Text>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', cursor: disabled ? 'default' : 'pointer' }} onClick={disabled ? undefined : (() => this.onBoolChange(false))}>
					<div><img src={!valueToShow ? radioFull : radioEmpty} /></div>
					<Text style={{ padding: 5, color: valueToShow ? theme.brandInfo : theme.headerColorDark, ...valueStyle }}>{systemMessages.no}</Text>
				</div>
			</div>
		);
	}
}

BooleanComponent = withStyles(extendedFormsStyle)(BooleanComponent);
BooleanComponent = injectIntl(BooleanComponent);







































class DateComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onDateChange = this.onDateChange.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.todayTS = localTSToUTC(moment().startOf('day'));
		this.state = {};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value && this.changeDateTimeValue)
			this.changeDateTimeValue(nextProps.value);
	}

	/** @param {moment.Moment | ''} newDate */
	onDateChange(newDate) {
		const { onChange, settings, /* project */ } = this.props;

		let newTS = newDate ? newDate.valueOf() : null;
		if (typeof newTS === 'string') {
			newTS = newTS.replace(/[,\.\-/]/g, '');
			if (newTS.length === 8) {
				let longTS = momentTZ.tz(newTS);
				longTS = longTS && longTS.valueOf && longTS.valueOf();
				newTS = longTS ? longTS : null;
			}
		}
		if (this.props.value === newTS)
			return;

		newTS = _.isNull(newTS) ? newTS : handleDateInputChange(newTS, settings);

		if (onChange)
			onChange(newTS);
	}

	/** @type {import("react-datetime").DatetimepickerProps['renderInput']} */
	renderInput(props, openCalendar, closeCalendar) {
		const { rtl, valueStyle = {} } = this.props;

		this.clearDateTimeInput = () => {
			props.onChange('');
			closeCalendar();
		};

		this.changeDateTimeValue = (newValue) => {
			props.onChange({ target: { value: newValue ? newValue : '' } });
		}

		return (
			<div style={{ position: 'relative' }}>
				<input {...props} style={{ fontSize: 'inherit', ...valueStyle }} />
				<div style={{ position: 'absolute', [rtl ? 'left' : 'right']: 0, top: 0, bottom: 0, display: 'flex', alignItems: 'center', margin: 8 }}>
					<img src={calendarIcon} />
				</div>
			</div>
		);
	}

	/** 
	 * @type {import("react-datetime").DatetimepickerProps['renderView']} 
	 * @param {import("react-datetime").ViewMode} viewMode
	 */
	renderView = (viewMode, renderDefault) => {
		return (
			<div>
				{renderDefault()}
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: theme.verticalMargin }}>
					{<Button title={systemMessages.clear} primaryColor={theme.brandDanger} onClick={this.clearDateTimeInput} />}
				</div>
			</div>
		);
	};

	/**
	 * @type {import("react-datetime").DatetimepickerProps['renderDay']} 
	 * @param {moment.Moment} currentDate
	 * @param {moment.Moment | null} selectedDate
	 */
	renderDay = (props, currentDate, selectedDate) => {

		return (
			<td {...props}>
				{currentDate.date()}
			</td>
		);
	};

	render() {
		const {
			placeholder, disabled,
			intl, settings, rtl,
			type, disabledValueStyle,
			dateFormat, position = 'bottom',
			withTooltip, isTableCell
		} = this.props;

		let info = checkPropSettings({ type, settings }, this.props.value, intl);
		const value = !_.isNil(this.props.value) ? UTCToLocalTS(this.props.value) : null;
		let isWarningOrExpired = Boolean(info.isWarning || info.isExpired);

		if (disabled) {
			let dateText = !value ? null : moment(value).format(intl.formatMessage(dateFormat || systemMessages.onlyDateFormat));
			let style =
				info.isExpired ? { fontWeight: theme.strongBold, color: theme.brandDanger } :
					info.isWarning ? { fontWeight: theme.strongBold, color: '#deac00' } : {};
			if (dateText && !isTableCell)
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text
							withTooltip={withTooltip}
							style={Object.assign(
								isWarningOrExpired && { [rtl ? 'marginLeft' : 'marginRight']: theme.paddingSize * 3 },
								{
									color: info.isExpired ? theme.brandDanger : theme.headerColorDark,
									fontWeight: info.isExpired ? theme.strongBold : null,
									fontSize: 18,
									...disabledValueStyle,
								},
							)}
						>
							{dateText}
						</Text>
						{Boolean(info.isWarning || info.isExpired) && (
							<img style={{ [rtl ? 'marginLeft' : 'marginRight']: 5 }} src={info.isExpired ? issue_full : issue_warn} />
						)}
						{Boolean(info.isWarning || info.isExpired) && !info.isCanceled && (
							<Text style={style}>
								{info.isExpired ? propertiesMessages.expired : propertiesMessages.closeExpiration}
							</Text>
						)}
					</div>
				);
			return (<ValueComponent withTooltip={withTooltip} valueText={dateText} defaultText={propertiesMessages.empty} style={disabledValueStyle} />);
		}

		return (
			<FormControl className={position} fullWidth>
				<Datetime
					closeOnSelect={true}
					renderInput={this.renderInput}
					renderView={this.renderView}
					timeFormat={false}
					value={value ? moment(value) : null}
					onChange={this.onDateChange}
					inputProps={{
						placeholder: disabled ? null : placeholder,
						disabled: disabled,
						fullWidth: true,
					}}
				/>
			</FormControl>
		);
	}
}

DateComponent = injectIntl(DateComponent);































const CLEAR_ICON_COLOR = 'rgb(204, 204, 204)';
const CLEAR_ICON_HOVER_COLOR = 'rgb(163, 163, 163)';

const CLEAR_ICON_HEIGHT = 20;
const CLEAR_ICON_WIDTH = 20;
const ClearIcon = (props) => {
	const { onClick, style } = props;
	const [hoverRef, isHover] = useHover();
	return (
		<svg
			onClick={onClick ? onClick : undefined}
			ref={hoverRef}
			height={CLEAR_ICON_HEIGHT}
			width={CLEAR_ICON_WIDTH}
			viewBox={`0 0 ${CLEAR_ICON_HEIGHT} ${CLEAR_ICON_WIDTH}`}
			focusable="false"
			style={Object.assign({ fill: isHover ? CLEAR_ICON_HOVER_COLOR : CLEAR_ICON_COLOR, cursor: onClick ? 'pointer' : 'unset' }, style)}
		>
			<path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
		</svg>
	);
};

let houseHeight = 18;
let houseWidth = 21;
class LocationPickerComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onSelectedLocationChange = this.onSelectedLocationChange.bind(this);
		this.renderInput = this.renderInput.bind(this);
		const { buildingId, floorId, unitId } = props.value || {};
		this.state = { isFocused: false, buildingId, floorId, unitId };
		this.locationPickerIconsContainerRef = createRef();
	}

	onSelectedLocationChange(event) { // TODO: support multi
		const { onChange, value, renderBuildingDirectly, multiple, shouldReturnFullLocation } = this.props;
		const { buildingId: stateBuildingId } = this.state;

		const { buildingId, floorId, unitId, isClearValue } = event;
		if (multiple) {
			const value = Object.values(event || {}).reduce((acc, obj) => {
				if (obj?.selected) {
					acc[obj.id] = obj.id;
				}

				return acc;
			}, {});

			onChange?.(value);
			return;
		}

		const isFirstBuildingClick = Boolean(stateBuildingId !== buildingId && buildingId && !(floorId || unitId));
		const shouldSendOnChange = Boolean(!isFirstBuildingClick || renderBuildingDirectly);

		const newVal = isClearValue ? null : handleSelectionListChange(value, false, unitId || floorId || buildingId);
		if (onChange && shouldSendOnChange) {
			let returnValue;
			if (!shouldReturnFullLocation)
				returnValue = newVal;
			else {
				returnValue = {
					selectedLocation: newVal,
					fullLocation: {
						unitId,
						floorId,
						buildingId
					}
				}
			}
			onChange(returnValue);
		}
		this.setState({ buildingId, floorId, unitId, isFocused: !shouldSendOnChange });
	}

	handleModalClose = () => {
		const { buildingId, floorId, unitId } = this.state;
		if (buildingId) // in case we wish to select the buidling on the first click
			this.onSelectedLocationChange({ buildingId, floorId, unitId });
		else
			this.setState({ isFocused: false });
	};

	handleClearInput = (e) => {
		e.stopPropagation();
		e.preventDefault();
		this.onSelectedLocationChange({ buildingId: null, floorId: null, unitId: null, isClearValue: true });
	};

	getCurrLocationString = (props) => { // TODO: support multi
		const { value, intl } = props || this.props;

		return Object
			.keys(value || {})
			.reduce((acc, locationId) => {
				const fullLocationDetails = getFullLocationDetailsByIdNoProps(locationId);
				if (fullLocationDetails.locationFound) {
					const locationString = getLocationTitleNoProps({
						intl,
						locationObj: fullLocationDetails.locationIds
					});

					acc.push(locationString);
				}
				return acc;
			}, [])
			.join('; ');
	};

	renderInput(props) {
		const {
			rtl, isDisabled, disabledValueStyle,
			renderBuildingDirectly, value, multiple,
			filterType,
		} = this.props;
		const { isFocused, buildingId, floorId, unitId } = this.state;
		const locationString = this.getCurrLocationString(this.props);

		if (isDisabled)
			return <ValueComponent valueText={locationString} defaultText={propertiesMessages.empty} style={disabledValueStyle} />;

		const buildingInput = Boolean(renderBuildingDirectly || isFocused) && (
			<Buildings
				showBadges={false}
				style={{ marginTop: 0, visibility: 'visible', width: '100%', height: '100%', backgroundColor: theme.backgroundColor }}
				maxUnitsInFloor={4}
				onSelectionChange={multiple ? this.onSelectedLocationChange : null}
				onClick={multiple ? null:  this.onSelectedLocationChange}
				selectedLocation={{ buildingId, floorId, unitId }}
				enableSearch
				isMultiSelect={multiple}
				selectedLocations={value}
				isSelectionMode={multiple}
				showSelectType={false}

				filterType={filterType}
			/>
		);

		if (renderBuildingDirectly)
			return buildingInput;

		const locationStringWidth = this.locationPickerIconsContainerRef.current ? `calc(100% - ${this.locationPickerIconsContainerRef.current.getClientRects()[0].width}px)` : '100%';

		return (
			<>
				<div 
					onClick={() => this.setState({ isFocused: !isFocused })}
					style={{ 
						position: 'relative', 
						paddingBottom: theme.verticalMargin, 
						borderBottom: isFocused ? '1.5px solid' : '1px solid', 
						borderColor: isFocused ? theme.brandPrimary : theme.placeholderTextColor, 
						cursor: isDisabled ? 'default' : 'pointer',
					}}
				>
					{locationString 
						? <div style={{ fontSize: theme.fontSize, width: locationStringWidth }}>{locationString}</div> 
						: <input placeholder={props.placeholder} onChange={props.onChange} rtl={String(props.rtl)} value="" style={{ fontSize: theme.fontSize, width: '100%', cursor: 'inherit' }} />
					}
					<div ref={this.locationPickerIconsContainerRef} style={{ position: 'absolute', [rtl ? 'left' : 'right']: 0, top: 0, bottom: theme.verticalMargin, display: 'flex', alignItems: 'center', margin: 8 }}>
						{Boolean(!isDisabled && locationString) && <ClearIcon style={{ margin: theme.margin }} onClick={this.handleClearInput} />}
						<img src={HomeBlackPNG} style={{ height: houseHeight, width: houseWidth, maxWidth: '60px', zIndex: theme.zIndexesLevels.one }} />
					</div>
				</div>

				{Boolean(buildingInput) &&
					<Modal onClose={this.handleModalClose} open={isFocused} style={{ zIndex: theme.zIndexes.locationPickerModal }}>
						<div style={{ height: '75vh', width: '20vw', minWidth: 300, maxWidth: 350, minHeight: 600 }}>
							{buildingInput}
						</div>
					</Modal>
				}
			</>
		);


	}


	render() {
		const { value, key, disabled, options, mode, noBorder, style, businessType, intl, placeholder } = this.props;

		return (
			this.renderInput(this.props)
		);
	}
}

class SelectComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onCreateOption = this.onCreateOption.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onRemoveOption = this.onRemoveOption.bind(this);
		this.state = {
			options: [],
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	setComponentData(props, nextProps) {
		const { intl } = nextProps;
		let newStateChanges = {};

		if (nextProps?.options && (props.firstMount || props.isValDiff(nextProps, ['options']))) {
			newStateChanges.options = [];
			_.values(nextProps?.options).forEach((curr, index) => {
				if (!curr) {
					return;
				}
				const optionTitle = curr?.title?.defaultMessage ? intl.formatMessage(curr.title) : curr.getNested(['getTitle']);
				const option = {
					value: curr.id,
					label: optionTitle?.trim(),
					isDeleted: curr.isDeleted,
					ordinalNo: curr.ordinalNo || index,
				};
				newStateChanges.options.push(option);
			});

			if (nextProps.autoSortOptions) {
				newStateChanges.options = newStateChanges.options.sort((a, b) => {
					const numeric = (!isNaN(a.label || '')) && (!isNaN(b.label || ''));
					return (a.label || '').localeCompare(b.label || '', undefined, { numeric });
				});
			} else {
				newStateChanges.options = newStateChanges.options.sort((a, b) => {
					return (a.ordinalNo || 0) - (b.ordinalNo || 0);
				});
			}

		}

		if (Object.keys(newStateChanges).length)
			this.setState(newStateChanges);
	}

	onSelectChange = (event, valueMeta) => {
		const { onChange, value, isMulti } = this.props;

		let newVal;
		if (valueMeta?.action === 'clear') {
			newVal = null;
		} else if (event) {
			const optionId = isMulti && valueMeta
				? ['remove-value', 'pop-value'].includes(valueMeta.action) ? valueMeta.removedValue.value : valueMeta.option.value
				: event.value;
			newVal = handleSelectionListChange(value, isMulti, optionId, true);
		}
		else
			newVal = null;

		if (onChange)
			onChange(newVal);
	};

	async onCreateOption(newLabel) {
		const { putSelectionListOptions, project, subjectName, propId, lang, startToast, onCreateOption } = this.props;
		const { options } = this.state;

		if (_.isFunction(onCreateOption))
			onCreateOption(newLabel);
		else {

			let newlySelectedOption;
			_.forIn(options, val => {
				if (val.label == newLabel)
					newlySelectedOption = val;
			});

			if (!newlySelectedOption || newlySelectedOption.isDeleted) {
				let valObject = {};

				if (newlySelectedOption && newlySelectedOption.isDeleted)
					valObject = { id: newlySelectedOption.value, isDeleted: false };
				_.set(valObject, ['title', lang], newLabel);

				let res = await putSelectionListOptions({ projectId: project.id, subjectName, propId, lang, values: [valObject] });

				if (!(res && res.success)) {
					startToast({ title: _.get(systemMessages, ['notAllowedOffline', 'title']), type: 'error' });
					return;
				}

				const newValId = _.chain(res)
					.get(['changedValues'])
					.filter(lang => _.some(lang?.title, title => title === newLabel))
					.head()
					.get(['id'])
					.valueOf();

				newlySelectedOption = { value: newValId, label: newLabel };
			}
			this.onSelectChange(newlySelectedOption);
		}
	}

	async onRemoveOption(option) {
		const { value: valueId, label } = option;
		const { onRemoveOption, removeSelectionListOption, startToast, project, subjectName, propId, intl } = this.props;

		const isUserSure = await new Promise(resolve =>
			startToast({
				overlay: true,
				mandatory: true,
				title: intl.formatMessage(systemMessages.manage.sureRemoveObject, { objectName: `"${label}"` }),
				actions: [
					{ message: systemMessages.yes, onClick: () => resolve(true), color: 'success' },
					{ message: systemMessages.no, onClick: () => resolve(false) }
				],
			}));

		if (isUserSure) {
			if (project && project.id && subjectName && propId) {


				const lang = project.getNested(['lang'], 'en');
				let res = await removeSelectionListOption({ projectId: project.id, subjectName, propId, lang, valueId });
				if (!(res && res.success)) {
					startToast({ title: _.get(systemMessages, ['notAllowedOffline', 'title']), type: 'error' });
					return;
				}
			}

			if (_.isFunction(onRemoveOption))
				onRemoveOption();
		}
	}

	render() {
		const { key, disabled, mode, noBorder, style, businessType, intl, placeholder, isCreatable = false, isRemovable = true, isClearable = true, createLabel = systemMessages.createSelectableOption, isSearchable, disabledValueStyle = {}, valueStyle = {}, isMulti } = this.props;
		
		const { options } = this.state;

		let selectedOptionsArr = _.values(options).reduce((acc, option) => {
			if (_.get(this.props.value, [option.value]))
				acc.push(option);

			return acc;
		}, []);



		const onRemove = this.onRemoveOption;
		const onCreate = this.onCreateOption;

		const RemovableOptionComponent = props => {
			const [isHovered, setHovered] = useState(false);
			const { innerRef, data } = props;

			const value = data?.value;
			const isNewOption = data?.__isNew__;

			const shouldDisplayTrashIcon = useMemo(
				() => isHovered && isCreatable && isRemovable && !isNewOption,
				[isHovered, isCreatable, isRemovable, isNewOption]
			);

			const innerComponent = useMemo(() => {

				let component = <SelectComponents.Option {...props} ref={innerRef} />;

				if (shouldDisplayTrashIcon) {
					component = (
						<div ref={innerRef} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<SelectComponents.Option {...props} ref={innerRef} />
							<EditStrip icons={[{ icon: trash, onClick: () => onRemove(data), style: { height: '18px' } }]} />
						</div>
					);
				}
				else if (isNewOption) {
					component = (
						<div ref={innerRef} style={{ display: 'flex', justifyContent: 'center' }}>
							<Button title={createLabel} onClick={(e) => onCreate(value)} />
						</div>
					);
				}

				return component;

			}, [shouldDisplayTrashIcon, isNewOption, innerRef, value]);




			return (
				<div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
					{innerComponent}
				</div>
			);
		};



		if (mode == "table") { // TODO: maybe remove cause I dont think we use this anymore
			if (selectedOptionsArr && businessType == 'trades')
				return <div style={{ display: 'flex', justifyContent: 'center' }}><TradeBadge ids={selectedOptionsArr} /></div>;
			else {
				const valueText = optionsToText({ options: this.props.options, intl, data: this.props.value });
				return <Text style={{ fontWeight: theme.noBold, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', ...style }}>{valueText}</Text>;
			}
		}

		if (noBorder) {
			selectStyles.container = styles => ({ ...styles, width: '100%', border: '0px', borderRadius: '0px', });
			selectStyles.option = styles => ({ ...styles, zIndex: theme.zIndexes.selectionListOption, cursor: 'pointer', color: theme.headerColorDark });
			selectStyles.control = styles => ({ ...styles, backgroundColor: 'transparent', border: '0px', boxShadow: '0px', cursor: 'pointer' });
		}

		if (disabled) {
			const valueText = optionsToText({ options: this.props.options, intl, data: this.props.value, numOfOptionsToShow: 5 });
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ValueComponent valueText={valueText} defaultText={propertiesMessages.empty} style={disabledValueStyle} />
					{Boolean(selectedOptionsArr && businessType == 'trades') && <TradeBadge mode={'min'} ids={selectedOptionsArr} />}
				</div>
			);
		}

		selectStyles.container = styles => ({ ...styles, ...style });
		selectStyles.singleValue = styles => ({ ...styles, ...valueStyle });
		selectStyles.multiValue = styles => ({ ...styles, ...valueStyle });
		selectStyles.input = styles => ({ ...styles, ...valueStyle });
		selectStyles.valueContainer = styles => ({ ...styles, padding: 0, ...valueStyle });
		selectStyles.option = (styles, state) => ({
			...styles,
			...Boolean(state.isSelected || state.isFocused) && { color: theme.brandPrimary },
			backgroundColor: 'transparent'
		});
		selectStyles.indicatorSeparator = _.noop;

		let componentProps = {
			key,
			value: selectedOptionsArr,
			placeholder: placeholder || intl.formatMessage(isCreatable ? systemMessages.selectOrCreatePlaceholder : systemMessages.selectPlaceholder),
			onChange: this.onSelectChange,
			styles: selectStyles,
			options: options.filter(v => !v.isDeleted),
			components: businessType == 'trades' ? { Option: TradeOptionComponent, SingleValue: TradeLableComponent } : { Option: RemovableOptionComponent },
			theme: (theme) => ({ ...theme, colors: { ...theme.colors, primary: theme.brandPrimary, }, }),
			isMulti,
			createOptionPosition: "first",
			isClearable,
			onClear: this.handleClear,
			isSearchable: true,
		};

		return isCreatable ? <Creatable {...componentProps} /> : <Selectable {...componentProps} />;
	}
}


const selectEnhance = compose(
	injectIntl,
	connect(state => ({}), { putSelectionListOptions, removeSelectionListOption, startToast })
);

SelectComponent = selectEnhance(SelectComponent);

let selectStyles = {
	valueContainer: styles => ({ ...styles, padding: '0px', margin: '0px', border: '0px', borderRadius: '0px' }),
	container: styles => ({ ...styles, width: '100%', border: '0px', borderRadius: '0px' }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			zIndex: theme.zIndexes.selectionListOption,
			cursor: 'pointer',
			backgroundColor: theme.backgroundColorBright,
			color: isDisabled
				? '#ccc'
				: isSelected
					? theme.brandPrimary
					: isFocused ? theme.brandPrimary : theme.brandNeutralDark,
			cursor: isDisabled ? 'not-allowed' : 'default',
		};
	},
	control: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: 'transparent',
			border: '0px',
			borderBottom: ((isFocused || isSelected) ? '1.5px solid ' + theme.brandPrimary : '1px solid hsl(0,0%,80%)'),
			borderRadius: '0px',
			boxShadow: '0px',
			cursor: 'pointer'
		};
	},
	menuList: styles => ({ ...styles, zIndex: theme.zIndexes.selectionListOption }),
	menu: styles => ({ ...styles, zIndex: theme.zIndexes.selectionListOption })
};

const TradeOptionComponent = props => {
	const { innerProps, innerRef } = props;
	return (<div ref={innerRef} {...innerProps} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: theme.verticalMargin, cursor: 'pointer' }}>
		<Text>{props.label}</Text>
		<TradeBadge mode={'min'} ids={[props.value]} />
	</div>);
};

const TradeLableComponent = props => {
	return (
		<div style={{ gridArea: '1/1/2/3', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
			<Text>{props.data.label}</Text>
			<TradeBadge mode={'min'} ids={[props.data.value]} />
		</div>
	);
};







const SignatureComponent = (props) => {
	const { disabled, value, settings, placeholder, valueStyle, onChange, selectedProjectId } = props;

	const { isShowSignatureValue, signatures } = settings || {};
	const signatureObjs = Object.values(signatures || {}).sort((a, b) => a.ordinalNo - b.ordinalNo);
	const [shouldDisplaySignModal, setShouldDisplaySignModal] = useState(false);
	const containerStyle = {height: 75, width: 150};

	const displayEls = (
		isShowSignatureValue
		? _.values(value).filter(signature => signature.uri).map(signature => (<Image regularImage key={`signature_${signature.id}`} src={signature.uri} imageContainerStyle={containerStyle}/>))
		: <Image regularImage src={signatureIcon} imageContainerStyle={containerStyle} />
	)
	if (!disabled)
		return (
		<>
		{Boolean(value) && displayEls}
		<Button
			onClick={() => setShouldDisplaySignModal(true)}
			shouldInvertColors 
			title={systemMessages.sign} />
			<SignatureDialog
			signatureObjs={signatureObjs}
			isOpen={shouldDisplaySignModal}
			handleClose={() => setShouldDisplaySignModal(false)}
			projectId={selectedProjectId}
			onSign={(signatures) =>{onChange(signatures); setShouldDisplaySignModal(false)}}
			/>
				
		</>);

	if (!value)
		return <Text style={valueStyle}>{placeholder}</Text>


	return displayEls
}



























class CertificationComponent extends React.Component {
	constructor(props) {
		super(props);
		this.today = moment(moment().format("YYYY-MM-DD")).utc().valueOf();
		this.setTemporaryCertificationMonthsTTL = this.setTemporaryCertificationMonthsTTL.bind(this);
		this.addMonth = this.addMonth.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			certificationsArr: [{}]
		};
	}

	componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData(props, nextProps) {
		const { lang } = nextProps;

		let newStateChanges = {};
		if (Array.isArray(nextProps.value)) {
			if (props.value != nextProps.value && !this.state.lastCertObj)
				newStateChanges.certificationsArr = (nextProps.value || []).slice();

			if (props.addMode != nextProps.addMode && nextProps.addMode) {
				newStateChanges.certificationsArr = (nextProps.value || []).slice();
				newStateChanges.certificationsArr.push({
					signatureTS: this.today,
					certificationTTL: nextProps.settings && nextProps.settings.certificationMonthsTTL ?
						this.addMonth(this.today, nextProps.settings.certificationMonthsTTL) :
						null
				});
			}
		}

		if (Object.keys(newStateChanges).length > 0)
			this.setState(newStateChanges);
	}

	setTemporaryCertificationMonthsTTL(months) {
		const { certificationsArr } = this.state;
		let lastCert = certificationsArr[certificationsArr.length - 1];
		let signatureTS = lastCert.signatureTS;
		if (months == '') months = '0';
		let futureDate = this.addMonth(signatureTS, months);
		let stateChanges = { temporaryCertificationMonthsTTL: months };
		if (signatureTS)
			stateChanges = Object.assign(stateChanges, this.onChange(futureDate, 'certificationTTL', true));
		this.setState(stateChanges);
	}

	addMonth(val, monthsCount) {
		if (isNaN(monthsCount))
			return null;
		let d = val ? new Date(val) : new Date();
		d.setMonth(d.getMonth() + Number(monthsCount));
		return d.getTime();
	}

	onChange(val, path, skipSetState) {
		const { onChange, settings } = this.props;
		const { certificationsArr, temporaryCertificationMonthsTTL } = this.state;
		let newCertificationsArray = certificationsArr.slice();
		let lastCert = Object.assign({}, (newCertificationsArray[newCertificationsArray.length - 1] || {}));
		lastCert[path] = val;

		if (path == 'signatureTS' && ((settings && settings.certificationMonthsTTL) || temporaryCertificationMonthsTTL)) {
			let monthsTTL = settings ? settings.certificationMonthsTTL : null;
			monthsTTL = temporaryCertificationMonthsTTL ? temporaryCertificationMonthsTTL : monthsTTL;
			let futureDate = this.addMonth(val, monthsTTL);
			lastCert.certificationTTL = futureDate;
		}

		if (newCertificationsArray.length > 0)
			newCertificationsArray[newCertificationsArray.length - 1] = lastCert;

		if (onChange)
			onChange(newCertificationsArray);

		let stateChanges = { certificationsArr: newCertificationsArray };
		if (!skipSetState)
			this.setState(stateChanges);
		return stateChanges;
	}

	render() {
		const { extraTypes, disabled, intl, mode, rtl, lang, style, prop, withResize, resizePercent, onImageSelect, settings, subjectName, disabledValueStyle, isCertificationCanceled} = this.props;
		const { certificationsArr, temporaryCertificationMonthsTTL } = this.state;

		const iconSize = 16;
		let lastCertObj = certificationsArr[certificationsArr.length - 1] || {};
		let newSettings = Object.assign({}, { isWarning: true, isExpiration: true }, settings);

		let extraComponents = null;
		if (extraTypes && extraTypes.length > 0)
			extraComponents = extraTypes.map(propId => {
				return (
					<FunctionalInput
						{...this.props}
						propId={propId}
						key={propId}
						isExtra={true}
						style={{ marginTop: 5, marginBottom: 5 }}
						inputKey={propId + '_' + disabled}
						value={lastCertObj[propId]}
						onChange={(propId, val) => {
							this.onChange(val, propId);
						}}
						onImageSelect={onImageSelect} />
				);
			});

		const setExpirationDate = true;

		return (
			<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
				<StandardInput
					disabledValueStyle={disabledValueStyle}
					type={'Date'}
					style={null}
					settings={newSettings}
					disabled={disabled}
					subjectName={subjectName}
					key={'dateInput'}
					rtl={rtl}
					mode={mode}
					name={intl.formatMessage(disabled || setExpirationDate ? propertiesMessages.expirationDate : propertiesMessages.signatureDate)}
					value={disabled || setExpirationDate ? lastCertObj.certificationTTL : lastCertObj.signatureTS}
					todayIfNull={true}
					isCertificationCanceled={isCertificationCanceled}
					onChange={(val) => this.onChange(val, 'certificationTTL')} />
				{Boolean(!setExpirationDate && mode != 'table') && Boolean(!disabled && (!settings || !settings.certificationMonthsTTL)) &&
					<StandardInput
						isCertificationCanceled={isCertificationCanceled}
						disabledValueStyle={disabledValueStyle}
						type={'Number'}
						style={null}
						settings={settings}
						disabled={disabled}
						subjectName={subjectName}
						key={'dateInput'}
						rtl={rtl}
						mode={mode}
						name={intl.formatMessage(propertiesMessages.validityDuration)}
						value={temporaryCertificationMonthsTTL}
						onChange={(val) => this.setTemporaryCertificationMonthsTTL(val)} />}
				{Boolean(mode != 'table' && extraComponents) && extraComponents}
				{Boolean(mode != 'table') &&
					<StandardInput
						type={'DrawingsArray'}
						isCertificationCanceled={isCertificationCanceled}
						disabledValueStyle={disabledValueStyle}
						style={null}
						mode={mode}
						disabled={disabled}
						subjectName={subjectName}
						key={'drawingArrayCert'}
						withResize={false}
						name={intl.formatMessage(propertiesMessages.attachedFiles)}
						value={lastCertObj.attachments}
						onChange={(arr) => this.onChange(arr, 'attachments')} />}
			</div>
		);
	}
}

CertificationComponent = withStyles(extendedFormsStyle)(CertificationComponent);
CertificationComponent = injectIntl(CertificationComponent);






























export const ValueComponent = (props) => {
	let { valueText, defaultText, style, withTooltip } = props;
	if (valueText && typeof valueText != 'string' && typeof valueText != 'number' && !(valueText.id && valueText.defaultMessage))
		valueText = null;

	return (<Text withTooltip={withTooltip} style={{ color: valueText ? theme.headerColorDark : theme.brandNeutral, fontSize: 18, lineHeight: '18px', ...style }}>{valueText || defaultText}</Text>);
};
