import { useCallback, useEffect, useMemo } from 'react';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import { setAdminMode } from '../../../common/users/actions';
import { replaceUser } from '../../../common/auth/actions';
import { saveUIParams } from '../../../common/ui/actions';
import theme from '../../assets/css/theme';
import ProjectsSelector from '../../views/Projects/ProjectsSelector.js';
import { HeaderLinks, HeaderSideLinks } from '../index';
import headerStyle from '../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx';
import withRouterHOC from '../Router/util/withRouterHOC.js';
import { hideLoading, startLoading } from '../../../common/app/actions.js';
import systemMessages from '../../../common/app/systemMessages.js';
import { useLoading } from '../../../common/hooks/useLoading.js';
import { SUBJECTS } from '../../../common/dataManager/subjects.js';
import DataManagerInstance from '../../../common/dataManager/DataManager.js';
import { getAllProjectsCompanies, saveCompaniesToLocalDB } from '../../../common/companies/funcs.js';
import { DATA_MANAGER_STATES } from '../../../common/dataManager/DataManagerConstants.js';
import * as ioEvents from '../../../common/lib/io/eventTypes';

let Header = (props) => {
  const {
    classes,
    rtl,
    intl,
    navigationParams,
    routes,
    onRouteSelected,
    isCementoTeamViewer,
    location,
    viewer,
    replaceUser,
    setAdminMode,
    projectPermitted,
    startLoading,
    hideLoading,
    match,
    selectedProjectId,
    setDidHeaderLoad,
    projects,
    companiesConfigurations,
    viewerProjects,
    lastVisitedProject
  } = props;

  const scope = useMemo(() => {
    return match.params?.scope || 'projects';
  }, [match.params?.scope]);

  const subjectsToLoad = useMemo(() => {
    return [
      ['global', SUBJECTS.VIEWER],
      ['global', SUBJECTS.PROJECTS],
      ['global', SUBJECTS.COMPANIES],
    ];
  }, [scope, selectedProjectId]);

  const { isLoading } = useLoading(subjectsToLoad);

  useEffect(() => {
    DataManagerInstance.loadAndConnect({ scope:'global', subject: 'projects', viewer });

    return () => {
      hideLoading('headerLoading');
    }
  }, [])

  useEffect(() => {
    if (projects?.size > 0) {
      fetchCompanies();
    }
  }, [projects?.size]);

  const fetchCompanies = useCallback(async () => {
    const companies = await getAllProjectsCompanies();
    if (companies) {
      saveCompaniesToLocalDB(Object.values(companies));
      DataManagerInstance.updateStatus({
        scope: 'global',
        scopeId: 'global',
        subject: SUBJECTS.COMPANIES,
        stateType: DATA_MANAGER_STATES.CONNECTION_STATUS,
        status: ioEvents.DATA_RECEIVED,
      });
    }
  }, []);
  

  useEffect(() => {
    if (isLoading) {
      startLoading({
        title: systemMessages.loadingMessage,
        overlay: true,
        hideOnBackgroundPress: false,
        operationId: 'headerLoading',
      });
    } else {
      setDidHeaderLoad(true);
      hideLoading('headerLoading');
    }
  }, [isLoading]);

  if (isLoading) return null;
  return (
    <div style={{ zIndex: theme.zIndexes.header, top: 0 }}>
      <AppBar
        className={classes.appBar}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: theme.headerColorDark,
          height: theme.headerHeight,
        }}>
        <div style={{ alignSelf: 'flex-start' }}>
          <ProjectsSelector
          lastVisitedProject={lastVisitedProject}
          companiesConfigurations={companiesConfigurations}
          viewerProjects={viewerProjects}
          viewer={viewer}
            style={{
              height: theme.headerHeight,
              display: 'flex',
              alignItems: 'center',
              width: theme.sidebarWidth,
            }}
          />
        </div>
        {Boolean(projectPermitted) && (
          <div style={{ height: '100%', overflow: 'hidden' }}>
            <HeaderLinks
              key='mainHeader'
              onRouteSelected={onRouteSelected}
              location={location}
              rtl={rtl}
              intl={intl}
              navigationParams={navigationParams}
              routes={routes}
              isCementoTeamViewer={isCementoTeamViewer && viewer.adminMode == 1}
            />
          </div>
        )}
        <div>
          <HeaderSideLinks
            setAdminMode={setAdminMode}
            replaceUser={replaceUser}
            rtl={rtl}
            viewer={viewer}
            isCementoTeamViewer={isCementoTeamViewer}
          />
        </div>
      </AppBar>
    </div>
  );
};

const styles = {
  primaryHeader: {
    transition: 'all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s',
    height: theme.headerHeight,
    zIndex: theme.zIndexes.headerTitles,
    borderBottom: theme.borderLineNeutralLight + '50',
  },
  secondaryTitle: {
    transition: 'all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s',
    zIndex: theme.zIndexes.headerTitles,
    padding: 20,
    width: '100%',
    lineHeight: '1.5em',
    color: 'white',
    fontSize: '40px',
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: 'bold',
    margin: 0,
  },
  secondaryTitleContainer: {
    minHeight: theme.headerHeight,
    zIndex: theme.zIndexes.headerTitles,
    width: '100%',
  },
};

Header = withStyles(theme.combineStyles(headerStyle, styles))(Header);
const enhance = compose(
  withRouterHOC,
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      lastVisitedProject: state.users.lastVisitedProject,
      companiesConfigurations: state.configurations.companiesMap,
      viewerProjects: state.projects.map,
      viewer: state.users.viewer,
    }), { setAdminMode, replaceUser, saveUIParams, startLoading, hideLoading })
);
export default enhance(Header);
