import theme from '../../../../../../../web/assets/css/theme';
import DotStatusText from '../../../../../../../web/components/CementoComponents/DotStatusText';
import { getStatusParams } from '../../../../../../issues/issueStates';
import './style.css';

const dotSize = 10;

const dotStyle = {
  height: dotSize,
  width: dotSize,
  marginInline: theme.margin / 3,
  flexShrink: 0,
};

const labelStyle = {
  flexShrink: 0,
};

const containerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  margin: '0 5px',
};

const IssueStateCell = ({ cellValue, cellContent, isSummaryCell }) => {
  if (isSummaryCell) return cellContent;
  const params = getStatusParams({ issueState: cellValue?.id });

  return (
    <div className='IssueStateCell IssueStateCell_root'>
      <div className='IssueStateCell__content'>
        <DotStatusText
          label={params.message}
          color={params.color}
          dotStyle={dotStyle}
          containerStyle={containerStyle}
          labelStyle={labelStyle}
        />
      </div>
    </div>
  );
};

export default IssueStateCell;
